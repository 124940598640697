import { observable, action, computed, makeObservable } from 'mobx';
import { useRef } from 'react';
import { sendRequest } from '../helpers/axiosWrapper';
import strings from '../helpers/localization';

class UserStore {

  constructor() {
    makeObservable(this, {
      user: observable,
      selected_type: observable,
      selected_status: observable,
      selected_status_text: observable,
      selected_resolve_status: observable,
      resolve_filter: observable,
      features:observable,
      status_filter: observable,
      start_filter: observable,
      end_filter: observable,
      filter_text: observable,
      call:observable,
      calls: observable,
      calls_back: observable,
      call_detail: observable,
      duration:observable,
      messages: observable,
      strings: observable,
      setFeatures:action,
      setUser: action,
      resetFilter: action,
      filter: action,
      setResolveType: action,
      setStatusType: action,
      setStartDate: action,
      setEndDate: action,

    });

    const user = localStorage.getItem("user");
    if (user) {
      this.setUser(JSON.parse(user), false);
    }


  }

  user = null;
  features={
    core:false,
    livestore:false
  };
  selected_type = "Technicians";
  selected_status = strings.all;
  selected_status_text = strings.all;
  selected_resolve_status = strings.all;
  filter_text = "";
  resolve_filter = false;
  status_filter = false;
  start_filter = false;
  end_filter = false;
  call={};
  calls = [];
  call_request = false;
  calls_back = [];
  duration = "";
  messages = [];
  selected_end_date = new Date();
  call_detail = null;
  strings = strings


  setUser(user, saveLocal = true) {
    this.user = user;
    // console.log(user)
    // this.setStrings("en")
    // this.user && this.user.company && this.setStrings(this.user.company.lang)
    if (saveLocal) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  getCalls = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        await sendRequest("technicians").then((resp) => {
          this.strings = strings;
          this.call_request = true;
          this.calls = resp.data.calls;
          this.calls_back = resp.data.calls;
          this.features = resp.data.company.features;
        })
        resolve(true)
      } catch (error) {

      }
    })
  }

  setFeatures = (features) => {
    this.features = features;
  }

  getCallById = async (call_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        await sendRequest(`technicians/call`, call_id, 'GET').then((resp) => {
        })
        resolve(true)
      } catch (error) {
        console.log(error)
      }
    })
  }

  getCall = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
          // console.log("🚀 ~ file: techStore.js ~ line 118 ~ UserStore ~ awaitsendRequest ~ resp", resp)
          this.call_request = true;
          let _call = this.calls.find((i)=>i.slug===id);
          console.log("🚀 ~ file: techStore.js ~ line 120 ~ UserStore ~ returnnewPromise ~ this.calls", this.calls)
          console.log("🚀 ~ file: techStore.js ~ line 120 ~ UserStore ~ returnnewPromise ~ _call", _call)
          Object.assign(this.call,_call);
          console.log("🚀 ~ file: techStore.js ~ line 121 ~ UserStore ~ awaitsendRequest ~ this.call", this.call)
          this.duration = this.call.duration;
          this.messages = this.call.messages ? this.call.messages : [];
        resolve(true)
      } catch (error) {

      }
    })
  }

  editCall = async (call)=> {
    return new Promise(async (resolve, reject) => {
      try {
        await sendRequest(`/technicians/call`, call , "PUT").then((resp) => {
          console.log(resp)
        })
        resolve(true)
      } catch (error) {
        console.log(error)
      }
    })
  }

  
  setCallDetail = (item) => {
    this.call_detail = item
    // console.log("item => ", item)
  }

  resetFilter = () => {
    this.calls = this.calls_back;
    this.status_filter = false;
    this.resolve_filter = false;
    this.start_filter = false;
    this.end_filter = false;
    this.filter_text = '';
    this.resolve_filter = strings.all;
    this.selected_status = strings.all;
    this.selected_start_date = '';
    this.selected_end_date = new Date();
  }

  filter = () => {
    const { resolve_filter, status_filter, start_filter, end_filter,
      selected_end_date, selected_resolve_status, selected_start_date, selected_status, filter_text } = this;
    this.calls = this.calls_back
    if (resolve_filter && selected_resolve_status !== "All") {
      this.calls = this.calls.filter(item => item.resolved === selected_resolve_status)
    }
    if (status_filter && selected_status !== "All") {
      this.calls = this.calls.filter(item => item.state === selected_status)
    }
    if (start_filter) {
      this.calls = this.calls.filter(item => new Date(item.createdAt) >= new Date(selected_start_date))
    }
    if (end_filter) {
      this.calls = this.calls.filter(item => new Date(item.createdAt) <= new Date(selected_end_date))
    }
    if (filter_text !== "") {
      this.calls = this.calls.filter(item => item.customer.indexOf(filter_text) > -1)
    }
  }

  setResolveType = (status) => {
    this.resolve_filter = true;
    this.selected_resolve_status = status
    this.filter()
  }

  setStatusType = (status, text) => {
    this.status_filter = true;
    this.selected_status = status;
    this.selected_status_text = text;
    this.filter()
  }

  setStartDate = (date) => {
    this.start_filter = true;
    this.selected_start_date = date;
    this.filter()
  }

  setEndDate = (date) => {
    this.end_filter = true;
    this.selected_end_date = date;
    this.filter()
  }

  setTextFilter = (text) => {
    this.filter_text = text;
    this.filter()
  }

}


const userStore = new UserStore();
export default userStore;