import LocalizedStrings from 'react-localization';

import { de } from './langs/de';
import { en } from './langs/en';
import { tr } from './langs/tr';

let strings = new LocalizedStrings({
  en: en,
  tr: tr,
  de: de,
});

export default strings;