export const tr = {
  "welcome": "SELFFIX'e Hoş Geldiniz",
  "welcome_desc": "E-posta adresiniz ve şifreniz ile giriş yapabilir ve müşterinin telefon numarasını yazarak video görüşmesini başlatabilirsiniz.",
  "sign_in": "Giriş Yap",
  "sign_in_desc": "E-posta adresiniz ve şifreniz ile giriş yapın",
  "email_address": "E-posta Adresi",
  "password": "Şifre",
  "customer_list": "Müşteri Listesi",
  "create_call": "Görüşme Oluştur",
  "status": "Durum",
  "all": "Tümü",
  "end": "Bitiş",
  "end": "Tamamlandı",
  "active": "Aktif",
  "customer_not_connect": "Müşteri Bağlı Değil",
  "resolve_status": "Çözümlenme Durumu",
  "true": "TRUE",
  "false": "FALSE",
  "min_date": "Başlangıç Tarihi",
  "max_date": "Bitiş Tarihi",
  "search": "Müşteri Arama",
  "clear_filters": "Filtreleri Temizle",
  "id": "Kimlik",
  "customer": "Müşteri",
  "duration": "Süre",
  "waiting": "Bekliyor",
  "resolved": "Çözüldü",
  "not_resolved": "Çözülmedi",
  "createdAt": "Oluşturulma Tarihi",
  "call_join_date": "Görüşme Katılma Tarihi",
  "call_end_date": "Görüşme Bitiş Tarihi",
  "confirm_submit": "Gönderimi Onayla",
  "are_you_sure": "Bunu yapmak istediğinizden emin misiniz?",
  "actions": "Eylemler",
  "call_actions": "Çağrı Eylemleri",
  "resolve": "Çözümle",
  "connect_the_call": "Görüşmeyi Başlat",
  "customer_is_not_connect": "Müşteri bağlı değil",
  "customer_is_not_connect": "Müşteri Bağlanamadı",
  "logout_header": "Çıkmak istediğinizden emin misiniz?",
  "logout": "Oturumu Kapat",
  "start_call_with": "Müşteri ile Görüşmeye Başla",
  "start_call_desc": "Müşterinin telefon numarasını yazarak video görüşmesini başlatabilirsiniz.",
  "start_call": "Görüşmeyi Başlat",
  "waiting_customer": "Bekleyen Müşteri...",
  "waiting_customer": "Müşteri Bekleniyor...",
  "tech_form": "Teknik Form",
  "call_id": "Görüşme Kimliği",
  "pin": "Pin",
  "phone_number": "Telefon Numarası",
  "date_of_call": "Görüşme Tarihi",
  "write_notes": "Buraya notlarınızı yazın.",
  "is_issue_solved": "Sorun çözüldü mü?",
  "yes_solved": "Evet, çözüldü.",
  "no_solved": "Hayır, yedek parça aşamasına geçin.",
  "answer_not_connect": "Müşteri görüşmeye bağlanamıyor...",
  "spare_part": "Yedek Parça",
  "spare_part_waiting": "Yedek parça bekleniyor.",
  "end_call": "Bu görüşmeyi sonlandırmak istediğinizden emin misiniz?",
  "end_spare": "Yedek parçaya ihtiyacınız olduğundan emin misiniz?",
  "customer_not_connect_call": "Müşteri çağrıya bağlanamıyor...",
  "yes": "Evet",
  "no": "Hayır",
  "enter_spare": "Yedek parça girin.",
  "finalize_form": "Formu tamamlayın.",
  "code_not_valid": "Kod geçerli değil!",
  "select_shape": "Bir şekil türü seçin!",
  "enter_pin": "Pin Kodu Gir",
  "enter_pin": "Başlat",
  "join_call": "Video Görüşmesine Katıl",
  "join_live": "Pin kodu ile canlı görüşmeye katıl.",
  "live_pin": "Canlı Görüşme Pin Kodu",
  "ended_chat": "Canlı Görüşme Sonlandı",
  "rate_service": "Görüntülü Destek Hizmeti için Puan Ver",
  "rate_us": "Bizi Puanla!",
  "please_rate_us": "Lütfen hizmetlerimizi puanlayın.",
  "rate_for_service": "Lütfen hizmetlerimizi puanlayın.",
  "call_finished": "Görüşme Tamamlandı",
  "call_has_finished": "Görüşmeniz tamamlandı.",
  "technicians_rate_service": "Teknisyenler, lütfen hizmeti değerlendirin",
  "add_note_review": "Derecelendirme notları ekleyin.",
  "submit": "Gönder",
  "thanks": "Teşekkürler!",
  "thanks_review": "İncelemeniz için teşekkür ederiz!",
  "leave_call": "Aramayı Sonlandır",
  "check_waiting_call": "Lütfen bekleyen bir aramanızın olup olmadığını kontrol edin.",
  "could_not_start": "Görüşme Başlatılamadı",
  "okay": "Tamam",
  "tech_note": "Teknisyen Notu",
  "digit_code": "4-haneli Kod",
  "technician": "Teknisyen",
  "message": "Mesaj",
  "selffix_customer_support": "Müşteri Destek",
  "end_chat": "Sohbeti Sonlandır",
  "end_chat_go_rating": "Sohbeti sonlandır ve değerlendir",
  "live_store": "Canlı Mağaza",
  "no_access": "ERİŞİM YOK",
  "download": "İndir",
  "notes": "Notlar",
  "cancel": "İptal",
  "note": "Not",
  "customer_support": "Müşteri Destek",
  "save": "Kaydet",
  "messages": "Mesajlar",
  "changes_saved": "Değişiklikler Kaydedildi!",
  "are_u_sure_to_exit": "Çıkmak istediğinizden emin misiniz?"
}
