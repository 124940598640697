import React, { Component } from 'react'
import { BrowserRouter as Router, Route, useParams, Redirect } from 'react-router-dom'
import { sendRequest } from "../../helpers/axiosWrapper";
import TechStore from "../../stores/techStore";
import SocketStore from "../../stores/socketStore";
import 'bulma/css/bulma.css'
import strings from '../../helpers/localization'
import "./login.css"

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      readyForLogin: false,
    }
  }

  componentWillMount() {
    SocketStore.init();
  }

  render() {
    if (this.state.readyForLogin) {
      return (
        <Redirect
          to={{
            pathname: "/home",
          }}
        />
      )
    }
    return (
      <div className='login-container' >
        <div className='left' >
          <img
            src={require("../../assets/images/login_img.png").default}
          // style={{height:"100%"}}
          />
          <div className='textBox'>
            <span className='title' >{strings.welcome}</span>
            <span className='text'>{strings.welcome_desc}</span>
          </div>

        </div>
        <div className='right'>
          <div className='rightInner'>
            <img
              className='login-icon'
              src={require("../../assets/images/loginIcon.png")}

            />
            <span className='signIn-text'>
              {strings.sign_in}
            </span>
            <span className='signIn-desc-text'>
              {strings.sign_in_desc}
            </span>
            <span className='email-text' >
              {strings.email_address}
            </span>
            <input onChange={(e) => {
              this.setState({ email: e.target.value })
            }} className='login-input' type="email" name="email" />
            <span className='password-text'>
              {strings.password}
            </span>
            <input onChange={(e) => {
              this.setState({ password: e.target.value })
            }} className='login-input' type="password" name="email" />
            <button onClick={async () => {
              const resp = await sendRequest("auth/technicians", this.state, "POST");
              const { data } = resp;
              if (data.done && data.user) {
                TechStore.setUser(data.user);
                this.setState({ readyForLogin: true });
              }
            }} className='login-btn' >
              {strings.sign_in}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;
