import { observable, action, computed, makeObservable } from 'mobx';
import { useRef } from 'react';
import { instance } from '../helpers/axiosWrapper';

class CustomizeStore {

  constructor() {
    makeObservable(this, {
      primary:observable,
      logo:observable,
      kvkk:observable,
      kvkkText:observable,
      kvkkWarning:observable,
      
      setPrimary: action,
      setLogo: action,
      setKvkk: action,
      setKvkkWarning:action,
      setkvkkText: action,
    });
  }


  setPrimary = (primary) => {
    this.primary = primary
  }
  setKvkkWarning = (kvkkWarning) => {
    this.kvkkWarning = kvkkWarning;
  }
  setLogo = (logo) => {
    this.logo = logo
  }
  setKvkk = (kvkk) => {
    this.kvkk = kvkk
  }
  setkvkkText = (kvkkText) => {
    this.kvkkText = kvkkText
  }
  
  primary = "#7A63FF";
  logo = require("../assets/images/selffix_white.png").default
  kvkk = false;
  kvkkWarning=""
  kvkkText = ""

}

const customizeStore = new CustomizeStore();
export default customizeStore;