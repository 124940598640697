export const de = {
  "welcome": "Willkommen bei SELFFIX",
  "welcome_desc": "Melden Sie sich mit Ihrer E-Mail und Ihrem Passwort an und Sie können den Videoanruf starten, indem Sie die Telefonnummer des Kunden eingeben.",
  "sign_in": "Einloggen",
  "sign_in_desc": "Melden Sie sich mit Ihrer E-Mail und Ihrem Passwort an",
  "email_address": "E-Mail-Adresse",
  "password": "Passwort",
  "customer_list": "Kundenliste",
  "create_call": "Anruf erstellen",
  "status": "Status",
  "all": "Alle",
  "end": "Ende",
  "active": "Aktiv",
  "customer_not_connect": "Kunde nicht verbunden",
  "resolve_status": "Status der Lösung",
  "true": "Richtig",
  "false": "Falsch",
  "min_date": "Startdatum",
  "max_date": "Enddatum",
  "search": "Kunden suchen",
  "clear_filters": "Filter löschen",
  "id": "ID",
  "customer": "Kunde",
  "duration": "Dauer",
  "waiting": "Warten",
  "resolved": "Gelöst",
  "not_resolved": "Nicht gelöst",
  "createdAt": "Erstellungsdatum",
  "call_join_date": "Anrufbeitrittsdatum",
  "call_end_date": "Anrufenddatum",
  "confirm_submit": "Sind Sie sicher, dass Sie dies abschicken möchten?",
  "are_you_sure": "Möchten Sie dies wirklich tun?",
  "actions": "Aktionen",
  "call_actions": "Anrufaktionen",
  "resolve": "Lösung",
  "connect_the_call": "Gespräch beginnen",
  "customer_is_not_connect": "Der Kunde kann keine Verbindung zum Anruf herstellen...",
  "logout_header": "Sind Sie sicher, dass Sie sich abmelden möchten?",
  "logout": "Ausloggen",
  "start_call_with": "Beginnen Sie das Gespräch",
  "start_call_desc": "Sie können den Videoanruf starten, indem Sie die Telefonnummer des Kunden eingeben.",
  "start_call": "Anruf Starten",
  "waiting_customer": "Kunde Erwartet...",
  "tech_form": "Technisches Formular",
  "call_id": "Anruf-ID",
  "pin": "Pin",
  "phone_number": "Telefonnummer",
  "date_of_call": "Anrufdatum",
  "write_notes": "Schreiben Sie hier Ihre Notizen.",
  "is_issue_solved": "Ist das Problem gelöst?",
  "yes_solved": "Ja, es ist gelöst.",
  "no_solved": "Nein, fahren Sie mit dem Ersatzteilbereich fort.",
  "answer_not_connect": "Der Kunde kann keine Verbindung zum Anruf herstellen...",
  "spare_part": "Ersatzteil",
  "spare_part_waiting": "Ersatzteile warten.",
  "end_call": "Sind Sie sicher, dass Sie dieses Gespräch beenden möchten?",
  "end_spare": "Sind Sie sicher, dass Sie ein Ersatzteil benötigen?",
  "customer_not_connect_call": "Der Kunde kann keine Verbindung zum Anruf herstellen...",
  "yes": "Ja",
  "no": "Nein",
  "enter_spare": "Geben Sie das Ersatzteil ein.",
  "finalize_form": "Das Formular abschließen.",
  "code_not_valid": "Der Code ist ungültig!",
  "select_shape": "Wählen Sie eine Formart aus!",
  "enter_pin": "Starten",
  "join_call": "Videoanruf Beitreten",
  "join_live": "Treten Sie dem Live-Chat mit Pin-Code bei.",
  "live_pin": "Live-Chat-Pin",
  "ended_chat": "Live-Chat Beendet",
  "rate_service": "Bewertung für den Service",
  "rate_us": "Bewerten Sie uns!",
  "please_rate_us": "Bitte bewerten Sie unsere Dienstleistungen.",
  "rate_for_service": "Bitte bewerten Sie den Service",
  "call_finished": "Der Anruf wurde beendet.",
  "call_has_finished": "Ihr Anruf wurde beendet.",
  "technicians_rate_service": "Techniker bitte bewerten Sie den Service",
  "add_note_review": "Notizen zur Bewertung hinzufügen.",
  "submit": "Einreichen",
  "thanks": "Danke!",
  "thanks_review": "Danke für deine Bewertung",
  "leave_call": "Anruf Beenden",
  "check_waiting_call": "Bitte überprüfen Sie, ob ein Warteanruf vorliegt.",
  "could_not_start": "Der Anruf konnte nicht gestartet werden.",
  "okay": "In Ordnung",
  "tech_note": "Technikerhinweis",
  "digit_code": "4-stelliger Code",
  "technician": "Techniker",
  "message": "Nachricht",
  "selffix_customer_support": "Kundensupport",
  "end_chat": "Chat Beenden",
  "end_chat_go_rating": "Chat beenden und bewerten",
  "live_store": "Live-Store",
  "no_access": "KEIN ZUGRIFF",
  "download": "Herunterladen",
  "notes": "Notizen",
  "cancel": "Abbrechen",
  "note": "Hinweis",
  "customer_support": "Kundensupport",
  "save": "Speichern",
  "messages": "Nachrichten",
  "changes_saved": "Änderungen gespeichert!",
  "are_u_sure_to_exit": "Sind Sie sicher, dass Sie beenden möchten?"
}