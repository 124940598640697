import io from "socket.io-client";
import meetingStore from "./meetingStore";
import messageStore from "./messageStore";
import screenshotStore from "./screenshotStore";
import userStore from "./techStore";
import { isMobile } from "react-device-detect";

const ENDPOINT = "https://api.selffix.co:5001";
// const ENDPOINT = "http://192.168.1.120:5002";

class Socket {
  socket = null;
  room = null;
  endCallFunc = () => {

  }

  init() {
    const auth = {
      token: (isMobile || !localStorage.getItem("user")) ? "SelffixClient" : userStore.user.token
    }

    console.log("auth", auth)
    this.socket = io(ENDPOINT, {
      transports: ['websocket'],
      secure: true,
      reconnection: true,
      reconnectionAttempts: 50,
      reconnectionDelay: 5000,
      autoConnect: true,
      auth
    });

    this.socket.on('connect', () => {
      console.log("CONNECT");
      this.socket.emit("channel_id", {
        id: this.room
      });
    });
    this.socket.on('error', (e) => {
      console.log(e);
    })
    console.log("geldi room", this.room);

    this.socket.on(this.room, (data) => {
      console.log("geldi", data);
      if (data.type === "dimensions") {
        meetingStore.setDimensions(data);
      } else if (data.type === "new_element") {
        meetingStore.insertNewElement(data);
      } else if (data.type === "drawing") {
        meetingStore.loadNewDrawer(data);
      } else if (data.type === "clean") {
        meetingStore.clean();
      } else if (data.type === "old_messages") {
        messageStore.setMessages(data.data)
      } else if (data.type === "message") {
        const { message } = data;
        messageStore.insertNewMessageFromRemote(message.content, message.content.type, new Date())
      } else if (data.type === "selectedColor") {
        meetingStore.setSelectedColor(data.data.color);
        console.log("🚀 ~ file: socketStore.js ~ line 47 ~ Socket ~ this.socket.on ~ data", data)
      } else if (data.type === "torch") {
        screenshotStore.torch();
      } else if (data.type === "setMute") {
        meetingStore.setIsMuted(data.data.isMuted);
      } else if (data.type === "end") {
        meetingStore.setEnd();
      } else if (data.type === "end_call_customer") {
        this.endCallFunc();
      }
    })
  }

  joinRoom(roomName) {
    this.room = roomName.chatId;
    this.init();
  }

  sendMessage(args, isTech = false) {
    console.log('args: ', args);
    const data = {
      payload: args,
      isTech,
      roomId: this.room
    }
    this.socket.emit("live", data);
  }

  setEndCallFunc = (endCallFunc) => {
    this.endCallFunc = endCallFunc;
  }
}

const socket = new Socket();
export default socket;