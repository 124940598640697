import React from 'react'
import { merge } from 'lodash'
// import AgoraRTC from 'agora-rtc-sdk'
import AgoraRTC from "agora-rtc-sdk-ng"

import * as MobileCheck from "react-device-detect";

import '../../assets/fonts/css/icons.css'

import SocketStore from "../../stores/socketStore";

import Elements from "../AgoraVideoCall/elements";
import screenshotStore from '../../stores/screenshotStore';
import { observer } from 'mobx-react'

import { useTorchLight } from '@blackbox-vision/use-torch-light';
import meetingStore from '../../stores/meetingStore';

const tile_canvas = {
  '1': ['span 24/span 24'],
  '2': ['span 24/span 24/24/24', 'span'],
  '3': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/19'],
  '4': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/13'],
  '5': ['span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 9/span 16/10/21'],
  '6': ['span 3/span 4/13/7', 'span 3/span 4/13/11', 'span 3/span 4/13/15', 'span 3/span 4/13/19', 'span 3/span 4/13/23', 'span 9/span 16/10/21'],
  '7': ['span 3/span 4/13/5', 'span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 3/span 4/13/25', 'span 9/span 16/10/21'],
}

let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  localMicTrack: null,
  client: null
};

const isMobile = MobileCheck.isMobile || !localStorage.getItem("user");

let _RemoteVideoTrack = null;

let _RemoteMicTrack;

const startWithUID = isMobile ? 1 : 9;

class AgoraCanvas extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      appId: props.appId,
      channel: props.channel,
      uid: parseFloat(startWithUID + "" + (new Date().getTime() % 10000000)),
      width: 0,
      height: 0
    }
  }

  componentWillUnmount() {
    console.log('rtc.localVideoTrack: ', rtc.localVideoTrack);
    if (rtc && rtc.localVideoTrack) {
      rtc.localVideoTrack.stop();
      rtc.localVideoTrack.close();
      rtc.localVideoTrack = null;
    }
    if (rtc && rtc.localMicTrack) {
      rtc.localMicTrack.stop();
      rtc.localMicTrack.close();
      rtc.localMicTrack = null;
    }

    rtc.client.leave()
  }

  componentDidMount() {

    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    rtc.client.on("user-published", async (user, mediaType) => {
      await rtc.client.subscribe(user, mediaType);
      if (mediaType === "audio") {
        console.log("🚀 ~ file: index.js ~ line 61 ~ AgoraCanvas ~ rtc.client.on ~ user", user)
        _RemoteMicTrack = user.audioTrack;
        console.log("🚀 ~ file: index.js ~ line 65 ~ AgoraCanvas ~ rtc.client.on ~ _RemoteMicTrack", _RemoteMicTrack)
        _RemoteMicTrack.play();
        return;
      }

      _RemoteVideoTrack = user.videoTrack;
      // Dynamically create a container in the form of a DIV element for playing the remote video track.
      const remotePlayerContainer = document.createElement("div");
      const b = document.getElementById("ag-canvas")
      // Specify the ID of the DIV container. You can use the uid of the remote user.
      remotePlayerContainer.id = user.uid.toString();
      remotePlayerContainer.style.cssText = `width:100%;height:100%`;

      b.append(remotePlayerContainer);
      screenshotStore.setTracker(_RemoteVideoTrack);
      // Play the remote video track.
      // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
      _RemoteVideoTrack.play(remotePlayerContainer);
      meetingStore.startInterval()
    });

    rtc.client.on("user-unpublished", user => {
      // Get the dynamically created DIV container.
      const remotePlayerContainer = document.getElementById(user.uid);
      console.log("🚀 ~ file: index.js ~ line 94 ~ AgoraCanvas ~ componentDidMount ~ user", user)
      // Destroy the container.
      if (remotePlayerContainer) {
        if (!user.hasVideo) {
          meetingStore.pauseInterval();
          remotePlayerContainer.remove();
        }
      }
    });

    rtc.client.join("ed4c0ffd56054721a1b4cd90e0cf0dfd", this.state.channel, null, this.state.uid).then(async () => {

      if (isMobile) {
        rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({ facingMode: "environment", mirror: false, torch: true });
        rtc.localMicTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, AGC: true, ANS: true });

        // rtc.localVideoTrack.setMuted(true);
        await rtc.client.publish([rtc.localVideoTrack, rtc.localMicTrack]);
        const localPlayerContainer = document.createElement("div");
        const height = document.getElementById('ag-container-id').clientHeight;
        const width = document.getElementById('ag-container-id').clientWidth;

        // Specify the ID of the DIV container. You can use the uid of the local user.
        localPlayerContainer.id = this.state.uid;

        localPlayerContainer.style.cssText = `width:${width}px;height:${height}px`;

        const b = document.getElementById("ag-canvas")

        b.append(localPlayerContainer);
        rtc.localVideoTrack.play(localPlayerContainer, { mirror: false });
        screenshotStore.setTracker(rtc.localVideoTrack);
        console.log("🚀 ~ file: index.js ~ line 100 ~ AgoraCanvas ~ rtc.client.joinX ~ rtc.localVideoTrack", rtc.localVideoTrack)
        // rtc.localVideoTrack.applyConstraints({
        //   advanced: [{torch: true}]
        // });
        meetingStore.setRTC(rtc);
        SocketStore.sendMessage({ width, height, type: "dimensions" });
      } else {
        rtc.localMicTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, AGC: true, ANS: true });
        meetingStore.setRTC(rtc);
        await rtc.client.publish([rtc.localMicTrack]);
      }

    });

  }

  closeTracks = () => {
    alert("OK");
  }

  sendElementToUser = () => {
    if (isMobile) return;
    const { x, y } = this.state;
    console.log("GELDI" + x + "#" + y);
    this.props.sendElementToUser(x, y);
  }

  handleMouse = (e) => {
    if (isMobile) return;
    this.setState({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });

  }

  render() {
    const style = {
      display: 'flex',
      gridGap: '10px',
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateRows: 'repeat(12, auto)',
      gridTemplateColumns: 'repeat(24, auto)',
      height: "100%"
    }
    // console.log(rtc.client ? JSON.stringify(rtc.client._users[0]) : null)
    // console.log(rtc.client ? rtc.client._users[0] : 'hello')
    return (
      <div id="ag-canvas" style={style} onClick={this.sendElementToUser} onMouseMove={this.handleMouse}  >
        {this.props.children}
        {/* <button style={{}} onClick={ async () => {
          const data = await _RemoteVideoTrack.getCurrentFrameData();
          
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          canvas.width = data.width;
          canvas.height = data.height;
          ctx.putImageData(data, 0, 0);

          const img = document.createElement("img");

          img.src = canvas.toDataURL();
          img.style.width = "640px";
          img.style.height = "480px";
          img.style.position = "absolute";
          img.style.top = "0px";
          img.style.left = "0px";

          document.body.append(img);

          
        }}>
          HELLo
        </button> */}

      </div>
    )
  }

}

export default AgoraCanvas