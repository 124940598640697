import React, { useState } from "react";

// import * as Cookies from "js-cookie";

import "./meeting.css";
import AgoraVideoCall from "../../components/AgoraV2";
import { AGORA_APP_ID } from "../../agora.config";
import { sendRequest } from "../../helpers/axiosWrapper";
import SocketStore from "../../stores/socketStore";
import MeetingStore from "../../stores/meetingStore";
import Loader from "react-loader-spinner";
import CanvasDraw from "react-canvas-draw";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Redirect,
} from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import html2canvas from "html2canvas";

import { saveAs } from "file-saver";

import Lottie from "react-lottie";
import moment from "moment";
import * as animationDone from "../../assets/lotties/done.json";

import * as MobileCheck from "react-device-detect";

import { observer } from "mobx-react";

import TechStore from "../../stores/techStore";
import strings from "../../helpers/localization";
import messageStore from "../../stores/messageStore";
import ElementsForSc from "../../components/AgoraVideoCall/elementsForSc";
import Elements from "../../components/AgoraVideoCall/elements";

import AWS from "aws-sdk";

import { D_O_S_A, D_O_S_S } from "../../agora.config";
import screenshotStore from "../../stores/screenshotStore";
import meetingStore from "../../stores/meetingStore";
import customizeStore from "../../stores/customizeStore";
import socket from "../../stores/socketStore";

const isMobile = MobileCheck.isMobile;

const spacesEndpoint = new AWS.Endpoint("fra1.digitaloceanspaces.com");
const end_call = require('../../assets/images/end_call.png');

const s3 = new AWS.S3({
  accessKeyId: D_O_S_A,
  secretAccessKey: D_O_S_S,
  endpoint: spacesEndpoint,
});

function uploadFile(file, name) {
  return new Promise((resolve) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: "selffix",
      ContentType: file.type,
      Key: `${name}/${file.name}`,
    };
    // console.log("🚀 ~ file: index.jsx ~ line 57 ~ returnnewPromise ~ params", params)

    s3.putObject(params)
      .on("build", (request) => {
        // request.httpRequest.headers.Host = "https://selffix.digitaloceanspaces.com";
        // request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = file.type;
        request.httpRequest.headers["x-amz-acl"] = "public-read";
      })
      .send((err, data) => {
        if (err) resolve(null);
        else {
          // If there is no error updating the editor with the imageUrl
          const Location = `https://selffix.fra1.cdn.digitaloceanspaces.com/${params.Key}`;
          resolve({ Location });
        }
      });
  });
}

const MuteButton = observer(() => {
  const { isMuted } = meetingStore;
  return (
    <div
      style={{}}
      onClick={() => {
        meetingStore.setIsMuted(!isMuted);
        const payload = { type: "setMute", data: { isMuted: !isMuted } };
        SocketStore.sendMessage(payload);
      }}
    >
      <img
        className="meeting-icon-img"
        src={
          !isMuted
            ? require("../../assets/images/micrp.png").default
            : require("../../assets/images/mute.png").default
        }
        alt=""
      />
    </div>
  );
});

const RenderMessageIcon = observer(() => {
  const { showBadge } = messageStore;
  return (
    <div
      onClick={() => {
        messageStore.setShowMessageBox(!messageStore.showMessageBox);
      }}
    >
      <img
        className="meeting-icon-img"
        src={require("../../assets/images/chaticon.png")}
        alt=""
      />
      {showBadge && <div className="showBadge" />}
    </div>
  );
});

const RenderMessageBoxIconForMobile = observer(() => {
  if (!isMobile) return null;
  const { showBadge } = messageStore;
  const { primary } = customizeStore;
  return (
    <div onClick={() => { messageStore.setShowMessageBox(true); }} style={{ borderRadius: 8, display: "flex", justifyContent: "center", alignItems: "center", width: 60, height: 60, backgroundColor: primary, position: "absolute", right: 20, bottom: 50, zIndex: 1000000, }}>
      <img
        className="chat-icon-logo"
        src={require("../../assets/images/chat_icon.png")}
        alt=""
        style={{ width: 30, height: 30 }}
      />
      {showBadge && <div style={{ position: "absolute", backgroundColor: primary, width: 20, right: -10, top: -10, height: 20, borderRadius: 10, borderWidth: 1, borderStyle: "solid" }} />}
    </div>
  );
});

const RenderEndCallForMobile = observer(({ onClick }) => {
  if (!isMobile) return null;
  return (
    <div onClick={() => {
      onClick();
    }} style={{ borderRadius: 8, display: "flex", justifyContent: "center", width: 60, height: 60, alignItems: "center", position: "absolute", left: "50%", right: "50%", transform: "translate(-50%, 0%)", bottom: 50, zIndex: 1000000, }}>
      <img
        className="chat-icon-logo"
        src={require("../../assets/images/end_call.png").default}
        alt=""
        style={{ width: 60, height: 60 }}
      />
    </div>
  );
});

const ColorPaletteIcon = observer(() => {
  const { selectedColor } = MeetingStore;
  let src;

  switch (selectedColor) {
    case "black": {
      src = require("../../assets/images/black-color-palette.png").default;
      break;
    }
    case "white": {
      src = require("../../assets/images/white-color-palette.png").default;
      break;
    }
    case "#DB3636": {
      src = require("../../assets/images/red-color-palette.png").default;
      break;
    }
    default: {
      src = require("../../assets/images/color-palette.png").default;
    }
  }

  return (
    <img
      className="meeting-icon-img"
      src={src}
      alt=""
    />
  );
});

const ColorPalette = observer(() => {
  const { showColorPalette } = MeetingStore;

  if (!showColorPalette) return null;
  return (
    <div
      className="color-palette"
    >
      <div
        onClick={() => {
          const payload = { type: "selectedColor", data: { color: "#7A63FF" } };
          SocketStore.sendMessage(payload);
          MeetingStore.setSelectedColor("#7A63FF");
        }}
        className="color-palette-box"
        style={{
          backgroundColor: "#7A63FF",
        }}
      />
      <div
        onClick={() => {
          const payload = { type: "selectedColor", data: { color: "white" } };
          SocketStore.sendMessage(payload);
          MeetingStore.setSelectedColor("white");
        }}
        style={{
          backgroundColor: "white",
        }}
      />
      <div
        onClick={() => {
          const payload = { type: "selectedColor", data: { color: "black" } };
          SocketStore.sendMessage(payload);
          MeetingStore.setSelectedColor("black");
        }}
        style={{
          backgroundColor: "black",
        }}
      />
      <div
        onClick={() => {
          const payload = { type: "selectedColor", data: { color: "#DB3636" } };
          SocketStore.sendMessage(payload);
          MeetingStore.setSelectedColor("#DB3636");
        }}
        style={{
          backgroundColor: "#DB3636",
        }}
      />
    </div>
  );
});

const RenderScreenShoot = observer(({ chatId, addFileToNote }) => {
  const { showScScreen, sc, selectedType, isDrawed, showLoading } =
    screenshotStore;
  const { selectedColor } = MeetingStore;
  if (!showScScreen && !sc) return null;

  const selectedStyle = {};
  const notSelectedStyle = {};

  let drawZIndex = 1;

  if (selectedType === "drawing") {
    drawZIndex = 1000001;
  } else if (isDrawed) {
    drawZIndex = 1;
  }

  return (
    <div
      className="render-scren-shoot"
    >
      <div
        id="sc_container_img"
        onClick={screenshotStore.onClick}
        onMouseMove={(e) => screenshotStore.handleMouse(e)}
        style={{ position: "relative", width: sc.width, height: sc.height }}
      >
        <img src={sc.toDataURL()} />
        <CanvasDraw
          ref={(d) => screenshotStore.setDrawer(d)}
          hideGrid={true}
          hideInterface={isMobile}
          disabled={isMobile || selectedType !== "drawing"}
          brushColor={selectedColor}
          brushRadius={2}
          lazyRadius={8}
          style={{
            zIndex: drawZIndex,
            top: 0,
            left: 0,
            backgroundColor: "transparent",
            position: "absolute",
            width: sc.width,
            height: sc.height,
          }}
        />
        <ElementsForSc />
      </div>
      <div
        className="render-scren-shoot-inner"
      >
        <div
          style={selectedType === "circle" ? selectedStyle : notSelectedStyle}
          onClick={() => screenshotStore.setSelectedType("circle")}
        >
          <img
            style={{ height: 50, width: 50, padding: "10px" }}
            src={
              selectedType === "circle"
                ? require("../../assets/images/circle-selected.png")
                : require("../../assets/images/circle.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "left-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => screenshotStore.setSelectedType("left-arrow")}
        >
          <img
            className="render-scren-shoot-img"
            src={
              selectedType === "left-arrow"
                ? require("../../assets/images/left-arrow-selected.png")
                : require("../../assets/images/left-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "right-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => screenshotStore.setSelectedType("right-arrow")}
        >
          <img
            className="render-scren-shoot-img"
            src={
              selectedType === "right-arrow"
                ? require("../../assets/images/right-arrow-selected.png")
                : require("../../assets/images/right-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={selectedType === "up-arrow" ? selectedStyle : notSelectedStyle}
          onClick={() => screenshotStore.setSelectedType("up-arrow")}
        >
          <img
            className="render-scren-shoot-img"
            src={
              selectedType === "up-arrow"
                ? require("../../assets/images/up-arrow-selected.png")
                : require("../../assets/images/up-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "bottom-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => screenshotStore.setSelectedType("bottom-arrow")}
        >
          <img
            className="render-scren-shoot-img"
            src={
              selectedType === "bottom-arrow"
                ? require("../../assets/images/bottom-arrow-selected.png")
                : require("../../assets/images/bottom-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={selectedType === "drawing" ? selectedStyle : notSelectedStyle}
          onClick={() => screenshotStore.setSelectedType("drawing")}
        >
          <img
            className="render-scren-shoot-img"
            src={
              selectedType === "drawing"
                ? require("../../assets/images/drawing-selected.png")
                : require("../../assets/images/drawing.png")
            }
            alt=""
          />
        </div>
        <div onClick={() => screenshotStore.clean()}>
          <img
            className="render-scren-shoot-img"
            src={require("../../assets/images/clean.png")}
            alt=""
          />
        </div>
        <div
          onClick={async () => {
            screenshotStore.setShowLoading(true);
            const elem = document.getElementById("sc_container_img");
            const location = await screenshotStore.sendSc(elem);
            await messageStore.sendMessage(chatId, "file", location);
            screenshotStore.clean();
            screenshotStore.setShowScScren(false);
            screenshotStore.setShowLoading(false);
          }}
        >
          <img
            className="render-scren-shoot-img"
            src={require("../../assets/images/send_sc.png").default}
            alt=""
          />
        </div>
        <div
          onClick={async () => {
            screenshotStore.setShowLoading(true);
            const elem = document.getElementById("sc_container_img");
            const location = await screenshotStore.sendSc(elem);
            addFileToNote(location);
            screenshotStore.setShowScScren(false);
            screenshotStore.clean();
            screenshotStore.setShowLoading(false);
          }}
        >
          <img
            className="render-scren-shoot-img"
            src={require("../../assets/images/floppy-disk.png").default}
            alt=""
          />
        </div>
        <div
          style={notSelectedStyle}
          onClick={() => {
            screenshotStore.clean();
            screenshotStore.setShowScScren(false);
          }}
        >
          <img
            className="render-scren-shoot-img"
            src={require("../../assets/images/kapa.png")}
            alt=""
          />
        </div>
      </div>
      {showLoading && (
        <div
          className="meeting-loader"
        >
          <Loader type="TailSpin" color="#7A63FF" height={100} width={100} />
        </div>
      )}
    </div>
  );
});

const EnterMessageBox = observer(({ chatId }) => {
  const { messageText } = messageStore;
  const { primary } = customizeStore;
  return (
    <div className="enter-message-box">
      {!isMobile && <div className="enter-message-box-top">
        <input onClick={(event) => {
          event.target.value = null
        }} onChange={async (e) => {
          const file = e.target.files[0];
          const response = await uploadFile(file, new Date().getTime());
          // console.log("🚀 ~ file: index.jsx ~ line 86 ~ <inputonChange={ ~ response", response)
          await messageStore.sendMessage(chatId, "file", response.Location);
        }} id="file" type="file" style={{ display: "none" }} />
        <label for="file">
          <img
            src={require("../../assets/images/attachment.png").default}
            alt=""
          />
        </label>
      </div>}
      <input
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            await messageStore.sendMessage(chatId, "text");
          }
        }}
        value={messageText} onChange={(e) => messageStore.setMessageText(e.target.value)} style={{ marginTop: 10, marginBottom: 10, marginLeft: isMobile ? 10 : 0, borderWidth: 0, borderRadius: 30, flex: 1, height: 50, paddingLeft: 10 }} placeholder={strings.message} type="text" name="Message" />
      <div onClick={async () => {
        // alert("ok")
        await messageStore.sendMessage(chatId, "text");
      }}>
        <div className="enter-message-box-bottom" style={{ backgroundColor: isMobile ? primary : "#7A63FF" }}>
          <img
            src={require("../../assets/images/send.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
});

const RenderMobileBox = observer(({ chatId }) => {
  const { innerWidth: width, innerHeight: height } = window;
  const { showMessageBox } = messageStore;
  const { primary } = customizeStore;
  if (!showMessageBox) return null;
  return (
    <div className="render-mobile-box" style={{ height: height - 150, width }}>
      <div className="render-mobile-box-inner">
        <div className="render-mobile-box-inner-top">
          <span style={{ color: primary }}>
            {strings.customer_support}
          </span>
        </div>
        <div onClick={() => {
          messageStore.setShowMessageBox(false)
        }}>
          <div className="render-mobile-box-inner-bottom">
            <div className="render-mobile-box-inner-bottom-inner" style={{ backgroundColor: primary }}>
              <img
                src={require("../../assets/images/kapa.png")}
                alt=""
              />
            </div>
          </div>

        </div>
      </div>
      <Messages />
      <EnterMessageBox chatId={chatId} />
    </div>
  );
});

const RenderMessageBox = observer(({ chatId }) => {
  const { showMessageBox } = messageStore;

  if (isMobile) return <RenderMobileBox />;

  const top = 250;
  const left = 65;

  return (
    <div className="render-message-box" style={{ top, left, visibility: showMessageBox ? "visible" : "hidden" }}>
      <div className="render-message-box-inner">
        <div className="render-message-box-inner-top">
          <img
            src={require("../../assets/images/live_chat.png")}
            alt=""
          />
          <span>
            {strings.customer} #{chatId}
          </span>
        </div>
        <div className="render-message-box-inner-bottom">
          <img
            src={require("../../assets/images/kapa.png")}
            alt=""
            onClick={() => {
              messageStore.setShowMessageBox(!showMessageBox)
            }}
          />
        </div>
      </div>

      <img
        src={require("../../assets/images/kapa.png")}
        alt=""
        onClick={() => {
          messageStore.setShowMessageBox(!showMessageBox);
        }}
      />

      <Messages />
      <EnterMessageBox chatId={chatId} />
    </div>
  );
});

const ShowFile = observer(() => {
  const { showFile } = messageStore;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (!showFile) return null;

  const { innerWidth: width, innerHeight: height } = window;
  const _width = width < 500 ? width : 500;

  return (
    <div
      className="show-file"
    >
      <div
        onClick={() => messageStore.setShowFile(null)}
        className="show-file-inner"
        style={{
          width: _width,
        }}
      >
        <img
          src={require("../../assets/images/kapa.png")}
        />
      </div>
      <img
        src={showFile}
        style={{ width: _width, objectFit: "cover", backgroundColor: "white" }}
      />
    </div>
  );
});

const Messages = observer(() => {
  const { messages } = messageStore;

  return (
    <div
      className="meeting-messages"
    >
      {messages.map((m, i) => {
        const isMy =
          (isMobile && m.from !== "technician") ||
          (!isMobile && m.from === "technician");
        const className = isMy ? 'my-messages-style' : 'other-messages-style';
        let _type;
        let _c = "";
        var _m = m.message.content.toString().match(/.*\/(.+?)\./);
        if (_m && _m.length > 1) {
          _c = _m[1];
        }
        if (m.message.type !== undefined) {
          if (m.message.type === "text") {
            _type = true;
          } else {
            _type = false;
          }
        } else {
          if (
            m.message.content.includes("http") ||
            m.message.content.includes("https")
          ) {
            _type = false;
          } else {
            _type = true;
          }
        }

        return (
          <div
            key={i + "messagesBox"}
            style={{
              alignSelf: "stretch",
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              justifyContent: isMy ? "flex-end" : "flex-start",
            }}
          >
            <div className={className}>
              {_type ? (
                <span
                  style={{
                    color: isMy ? "white" : "black",
                    fontSize: "1.2rem",
                  }}
                >
                  {m.message.content}
                </span>
              ) : m.message.content.includes("pdf") ? (
                <div style={{ color: !isMy ? "black" : "white" }}>
                  <a
                    style={{ color: isMy ? "white" : "black" }}
                    target="_blank"
                    href={m.message.content}
                  >
                    {_c}
                  </a>
                </div>
              ) : (
                <div
                  style={{ color: !isMy ? "black" : "white" }}
                  onClick={() => {
                    messageStore.setShowMessageBox(false);
                    messageStore.setShowFile(m.message.content);
                  }}
                >
                  <u>{_c}</u>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    color: isMy ? "white" : "black",
                    fontSize: "0.65rem",
                  }}
                >
                  {moment(m.createdAt).format("HH:mm")}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      <div
        ref={(s) => messageStore.setScrollContainer(s)}
        style={{ width: 1, height: 1 }}
      />
    </div>
  );
});

class HoverButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }
  render() {
    const { isHover } = this.state;
    const { colors, stylez } = this.props;
    const backgroundColor = isHover ? colors[1] : colors[0];
    return (
      <div
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
        {...this.props}
        className="hover-btn"
        style={{
          backgroundColor,
          color: isHover ? "white" : "#515151",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

const closeZoom = () => {
  var viewportMeta = document.createElement('meta');

  viewportMeta.name = 'viewport';
  viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';

  var head = document.head || document.getElementsByTagName('head')[0];

  head.appendChild(viewportMeta);
}

class PinEnter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      ready: false,
      disabled: true
    }

  }

  componentDidMount() {

    closeZoom();
    const pin = localStorage.getItem("pin");
    sendRequest("/auth/theme", {
      id: this.props.chatId
    }, "POST").then(({ data }) => {
      customizeStore.setPrimary(data.theme.theme.primaryColor ? data.theme.theme.primaryColor : "#7A63FF");
      customizeStore.setKvkk(data.theme.kvkk.url);
      customizeStore.setkvkkText(data.theme.kvkk.text);
      customizeStore.setLogo(data.theme.logo);
      customizeStore.setKvkkWarning(data.theme.kvkk.warning)
      this.setState({ ready: true, disabled: data.theme.kvkk.url ? true : false });
    })
    if (pin) {
      this.props.pin(pin, true);
    }
  }

  render() {
    const { strings } = TechStore;
    if (!this.state.ready) return null;
    return (
      <div className="pin-enter">
        <div style={{ backgroundColor: customizeStore.primary, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            className="header-logo"
            src={customizeStore.logo ? customizeStore.logo : require("../../assets/images/selffix_white.png")}

            alt=""
            style={{
              width: "240px",
              height: "70px",
              marginTop: 64,
              marginBottom: 64,
            }}
          />
        </div>
        <span style={{ fontSize: "1.8rem", fontWeight: "bold", marginLeft: 16, marginTop: 32, }}>{strings.join_call}</span>
        <span style={{ fontSize: "1.2rem", color: "#C9C9C9", marginLeft: 16, marginTop: 8, }}>{strings.join_live}</span>
        <span style={{ fontSize: "1.2rem", color: "black", marginLeft: 16, marginTop: 32, }}>{strings.live_pin}</span>
        <input autoFocus value={this.state.pin} onChange={(e) => {
          const { pin } = this.state;
          if (pin.length < 4 || e.target.value.length < 4) {
            this.setState({ pin: e.target.value })
          }
        }} style={{ marginTop: 10, outlineColor: customizeStore.primary, height: "50px", fontSize: 21, marginLeft: 16, marginRight: 16, backgroundColor: "#F2F2F2", borderWidth: 0, borderRadius: 4, paddingLeft: 8 }} placeholder={strings.digit_code} type="text" name="Pin" />
        {
          customizeStore.kvkk ?
            <div style={{ display: "flex", flexDirection: "row", marginTop: 16, marginLeft: 16, marginRight: 16 }}>
              <div onClick={() => {
                this.setState({ disabled: !this.state.disabled })
              }} style={{ width: 50, height: 50, flex: 1, display: "flex" }}>
                <div style={{ width: 30, height: 30, backgroundColor: "#FAFAFA", borderColor: this.state.disabled ? "#A8A8A8" : customizeStore.primary, borderWidth: 2, borderStyle: "solid", marginLeft: 3, borderRadius: 15 }}>
                  {!this.state.disabled && <div style={{ width: 18, height: 18, margin: 4, backgroundColor: customizeStore.primary, borderRadius: 30 }} />}
                </div>
              </div>
              <div style={{ flex: 10, display: "flex" }}>
                <a target="_blank" href={customizeStore.kvkk} style={{ fontSize: "0.8rem", marginLeft: 8 }}>
                  {customizeStore.kvkkText}
                </a>
              </div>
            </div>
            : null
        }
        <button onClick={async () => {
          if (this.state.disabled) {
            alert(customizeStore.kvkkWarning);
            return;
          }
          localStorage.setItem("pin", this.state.pin);
          this.props.pin(this.state.pin)
        }} style={{ opacity: !this.state.disabled ? 1 : 0.3, borderRadius: 4, borderWidth: 0, fontWeight: "600", color: "white", backgroundColor: customizeStore.primary, marginTop: 16, paddingTop: 16, paddingBottom: 16, fontSize: "1.3rem", marginLeft: 16, marginRight: 16 }}>
          {strings.enter_pin}
        </button>
      </div>
    );
  }
}

class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      pinEntered: false,
      selectedType: null,
      note: null,
      sparePart: "",
      call: null,
      needSparePart: false,
      showLottie: true,
      point: [false, false, false, false, false],
      techPoint: [false, false, false, false, false],
      techPointReady: false,
      techSubmitReview: false,
      pointReady: false,
      submit_review: false,
      rateIndex: 0,
      tech_review: false,
      meetingTime: 0,
      mobileLang: null,
      showMessageBox: false,
    };
    this.handleUnload = this.handleUnload.bind(this);
    socket.setEndCallFunc(this.submitEndChat)
    // this.videoProfile = Cookies.get("videoProfile").split(",")[0] || "480p_4";
    // this.channel = Cookies.get("channel") || "test";
    // this.transcode = Cookies.get("transcode") || "interop";
    // this.attendeeMode = Cookies.get("attendeeMode") || "video";
    // this.baseMode = Cookies.get("baseMode") || "avc";
    // this.appId = AGORA_APP_ID;
    // if (!this.appId) {
    //   return alert("Get App ID first!");
    // }
    // this.uid = undefined;
  }

  sendElementToUser = (x, y, strings) => {
    const { selectedType } = this.state;
    if (!selectedType) {
      return alert(strings.select_shape);
    }
    if (selectedType === "drawing") {
      const data = this.draw.getSaveData();
      const payload = { type: "drawing", data };
      console.log(payload);
      SocketStore.sendMessage(payload);
    } else {
      const payload = { x, y, type: "new_element", selectedType };
      SocketStore.sendMessage(payload);
      MeetingStore.insertNewElement(payload);
    }
  };

  componentDidMount() {
    const { chatId } = this.props.match.params;
    // window.addEventListener('beforeunload', this.handleUnload);

    // alert(chatId)
    this.videoProfile = "720P_6";
    this.channel = chatId;
    this.transcode = "interop";
    this.attendeeMode = "video";
    this.baseMode = "avc";
    this.appId = AGORA_APP_ID;
    this.uid = undefined;
    this.setState({ ready: true });

    if (!isMobile && !localStorage.getItem("user")) {
      if (window.location.href.includes("?reload=1")) {
        window.location.replace("https://app.selffix.co");
      } else {
        const rep = window.location.href + "?reload=1";
        window.location.replace(rep);
      }
    }

  }

  async componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  handleUnload(e) {
    var message = strings.are_u_sure_to_exit;
    // const a = await axios.get("technicians");
    // console.log("aa => ", a)

    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }

  clean = () => {
    this.setState({ selectedType: null });
    const payload = { type: "clean" };
    MeetingStore.clean();
    SocketStore.sendMessage(payload);
  };

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    SocketStore.joinRoom(this.props.match.params);
    // return {
    //   width,
    //   height
    // };
  };

  sendPin = async (pin, reconnect = false) => {
    const { chatId } = this.props.match.params;
    const { strings } = TechStore;
    try {
      const resp = await sendRequest(
        "auth/call",
        {
          id: chatId,
          pin,
          is_client: isMobile,
          reconnect: isMobile && reconnect,
        },
        "POST"
      );

      const { data } = resp;
      const { call, done } = data;
      if (done) {
        this.setState({ pinEntered: true, call, showRating: call.showRating });
        this.getWindowDimensions();
        if (call.company) {
          document.title = call.company.name
        } else {
          document.title = "SELFFIX"
        }
      }
    } catch (e) {
      console.log(e);
      alert(strings.code_not_valid);
      localStorage.removeItem("pin");
    }
  };

  renderToolBar = () => {
    if (isMobile) return null;
    const { chatId } = this.props.match.params;
    const { selectedType } = this.state;
    const selectedStyle = {
      borderLeftWidth: 4,
      borderLeftColor: "#7A63FF",
      borderLeftStyle: "solid",
    };
    const notSelectedStyle = {
      marginLeft: 4,
    };
    return (
      <div
        className="render-tool-bar"
      >
        <div
          style={selectedType === null ? selectedStyle : notSelectedStyle}
          onClick={() => this.setState({ selectedType: null })}
        >
          <img
            style={{ height: 50, width: 50, padding: "10px" }}
            src={
              selectedType === null
                ? require("../../assets/images/cursor-selected.png")
                : require("../../assets/images/cursor.png")
            }
            alt=""
          />
        </div>
        <div style={{ marginLeft: 4 }}>
          <div
            onClick={() => {
              MeetingStore.setShowColorPalette(!MeetingStore.showColorPalette);
            }}
          >
            <ColorPaletteIcon />
          </div>
          <ColorPalette />
        </div>
        <div
          style={selectedType === "circle" ? selectedStyle : notSelectedStyle}
          onClick={() => this.setState({ selectedType: "circle" })}
        >
          <img
            style={{ height: 50, width: 50, padding: "10px" }}
            src={
              selectedType === "circle"
                ? require("../../assets/images/circle-selected.png")
                : require("../../assets/images/circle.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "left-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => this.setState({ selectedType: "left-arrow" })}
        >
          <img
            className="render-tool-bar-img"
            src={
              selectedType === "left-arrow"
                ? require("../../assets/images/left-arrow-selected.png")
                : require("../../assets/images/left-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "right-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => this.setState({ selectedType: "right-arrow" })}
        >
          <img
            className="render-tool-bar-img"
            src={
              selectedType === "right-arrow"
                ? require("../../assets/images/right-arrow-selected.png")
                : require("../../assets/images/right-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={selectedType === "up-arrow" ? selectedStyle : notSelectedStyle}
          onClick={() => this.setState({ selectedType: "up-arrow" })}
        >
          <img
            className="render-tool-bar-img"
            src={
              selectedType === "up-arrow"
                ? require("../../assets/images/up-arrow-selected.png")
                : require("../../assets/images/up-arrow.png")
            }
            alt=""
          />
        </div>
        <div
          style={
            selectedType === "bottom-arrow" ? selectedStyle : notSelectedStyle
          }
          onClick={() => this.setState({ selectedType: "bottom-arrow" })}
        >
          <img
            className="render-tool-bar-img"
            src={
              selectedType === "bottom-arrow"
                ? require("../../assets/images/bottom-arrow-selected.png")
                : require("../../assets/images/bottom-arrow.png")
            }
            alt=""
          />
        </div>

        <div
          style={selectedType === "drawing" ? selectedStyle : notSelectedStyle}
          onClick={() => {
            this.setState({ selectedType: "drawing" });
            MeetingStore.setShowDrawer(true);
          }}
        >
          <img
            className="render-tool-bar-img"
            src={
              selectedType === "drawing"
                ? require("../../assets/images/drawing-selected.png")
                : require("../../assets/images/drawing.png")
            }
            alt=""
          />
        </div>
        <MuteButton />
        {/* <div style={notSelectedStyle} onClick={() => {
          const html = document.getElementById("ag-container-id");
          console.log(html);
          html2canvas(html).then(canvas => {
            canvas.toBlob(function (blob) {
              saveAs(blob, "pretty image.png");
            });
          }
          )
        }}>
          <img
            style={{ height: 50, width: 50, objectFit: "contain", padding: "10px" }}
            src={require("../../assets/images/screenshot.png")}
            alt=""
          />
        </div> */}
        <div onClick={() => SocketStore.sendMessage({ type: "torch" })}>
          <img
            className="render-tool-bar-img"
            src={require("../../assets/images/flashlight.png").default}
            alt=""
          />
        </div>
        <div onClick={() => this.clean()}>
          <img
            className="render-tool-bar-img"
            src={require("../../assets/images/clean.png")}
            alt=""
          />
        </div>
        <div onClick={() => screenshotStore.takeScreenshot()}>
          <img
            className="render-tool-bar-img"
            src={require("../../assets/images/landscape.png").default}
            alt=""
          />
        </div>
        <div>
          <RenderMessageIcon />
          <RenderMessageBox chatId={chatId} />
        </div>
      </div>
    );
  };

  contWithSparePart = (strings) => {
    confirmAlert({
      title: "",
      message: strings.end_spare,
      buttons: [
        {
          label: strings.yes,
          onClick: async () => {
            this.setState({ needSparePart: true });
          },
        },
        {
          label: strings.no,
          onClick: () => { },
        },
      ],
    });
  };

  renderButtons = (strings) => {
    const { sparePart, needSparePart } = this.state;
    if (needSparePart) {
      return (
        <div
          className="render-btns"
        >
          <span>
            {strings.spare_part}
          </span>
          <textarea
            placeholder={strings.enter_spare}
            onChange={(e) => this.setState({ sparePart: e.target.value })}
            className="meeting-textArea"
            rows="1"
            cols="1"
          ></textarea>
          <HoverButton
            colors={["#F5F5F5", "#7A63FF"]}
            onClick={() => this.setState({ isEndCall: true })}
          >
            {strings.finalize_form}
          </HoverButton>
        </div>
      );
    }
    return (
      <div
        className="render-btns"
      >
        <span>
          {strings.is_issue_solved}
        </span>
        <HoverButton
          colors={["#F5F5F5", "#7A63FF"]}
          onClick={() => this.setState({ isEndCall: true })}
        >
          {strings.yes_solved}
        </HoverButton>
        <HoverButton
          colors={["#F5F5F5", "#7A63FF"]}
          onClick={() => this.contWithSparePart(strings)}
        >
          {strings.no_solved}
        </HoverButton>
        <HoverButton
          colors={["#F5F5F5", "#7A63FF"]}
          onClick={() => this.endLiveChat(strings, true)}
        >
          {strings.answer_not_connect}
        </HoverButton>
      </div>
    );
  };

  renderTechForm = (strings) => {
    if (isMobile) return null;
    const { chatId } = this.props.match.params;
    const pin = localStorage.getItem("pin");
    return (
      <div
        className="render-tech-form"
      >
        <div
          className="render-tech-form-top"
        >
          <span>
            {strings.tech_form}
          </span>
        </div>
        <div
          className="render-tech-form-bottom"
        >
          <div
            className="render-tech-form-bottom-box"
          >
            <span className="render-tech-form-bottom-box-first-span">
              {strings.call_id}:
            </span>
            <span
              className="render-tech-form-bottom-box-second-span"
            >
              {chatId}
            </span>
          </div>
          <div
            className="render-tech-form-bottom-box"
          >
            <span className="render-tech-form-bottom-box-first-span">
              {strings.pin}:
            </span>
            <span
              className="render-tech-form-bottom-box-second-span"
            >
              {pin}
            </span>
          </div>
          <div
            className="render-tech-form-bottom-box"
          >
            <span className="render-tech-form-bottom-box-first-span">
              {strings.phone_number}:
            </span>
            <span
              className="render-tech-form-bottom-box-second-span"
            >
              +{this.state.call.customer}
            </span>
          </div>
          <div
            className="render-tech-form-bottom-box"
          >
            <span className="render-tech-form-bottom-box-first-span">
              {strings.date_of_call}:
            </span>
            <span
              className="render-tech-form-bottom-box-second-span"
            >
              {moment(this.state.call.createdAt).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              backgroundColor: "#F0F0F0",
              height: 1,
              marginTop: 8,
            }}
          />
          <textarea
            placeholder={strings.write_notes}
            value={this.state.note}
            onChange={(e) => this.setState({ note: e.target.value })}
            className="meeting-textArea"
            name="message"
            rows="10"
            cols="30"
          ></textarea>
          {this.renderButtons(strings)}
        </div>
      </div>
    );
  };

  rateChanger = (index) => {
    let tempArray = [false, false, false, false, false];
    for (let i = 0; i < index + 1; i++) {
      tempArray[i] = true;
    }
    this.setState({ point: tempArray, pointReady: true });
  };

  rateChangerTech = (index) => {
    let tempArray = [false, false, false, false, false];
    for (let i = 0; i < index + 1; i++) {
      tempArray[i] = true;
    }
    this.setState({
      techPoint: tempArray,
      techPointReady: true,
      rateIndex: index + 1,
    });
  };

  starContainer = (filled, index) => {
    let icon = filled
      ? require("../../assets/images/filled_star.png")
      : require("../../assets/images/empty_star.png");
    return (
      <div onClick={() => this.rateChanger(index)}>
        <img
          className="header-logo"
          src={icon}
          alt=""
          style={{ width: 45, height: 45 }}
        />
      </div>
    );
  };

  starContainerTech = (filled, index) => {
    let icon = filled
      ? require("../../assets/images/filled_star.png")
      : require("../../assets/images/empty_star.png");
    return (
      <div onClick={() => this.rateChangerTech(index)}>
        <img
          className="header-logo"
          src={icon}
          alt=""
          style={{ width: 45, height: 45 }}
        />
      </div>
    );
  };

  renderRateUs = (strings) => {
    // setTimeout(() => {
    //   this.setState({ showLottie: false })
    // }, 3000);

    return (
      <div>
        {this.state.showLottie ? (
          this.renderVideoEndLottie(strings)
        ) : !this.state.submit_review ? (
          <div
            className="render-rate-us"
          >
            <div
              className="render-rate-us-inner"
            >
              <img
                className="header-logo"
                src={require("../../assets/images/rate_logo.png").default}
                alt=""
                style={{ width: 100, height: 100 }}
              />
              <span
                className="render-rate-us-inner-first-span"
              >
                {strings.rate_us}
              </span>
              <span style={{ fontSize: 16, color: "#C9C9C9" }}>
                {strings.rate_for_service}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 45,
                  marginBottom: 56,
                }}
              >
                {this.state.point.map((star, index) => {
                  return this.starContainer(star, index);
                })}
              </div>
              <button
                disabled={!this.state.pointReady}
                onClick={async () => {
                  const pin = localStorage.getItem("pin");
                  const { chatId } = this.props.match.params;
                  let _point = 0;
                  for (let i = 0; i < this.state.point.length; i++) {
                    if (this.state.point[i] === true) {
                      _point += 1;
                    }
                  }
                  const resp = await sendRequest(
                    "rate",
                    {
                      pin: pin,
                      rating: _point,
                      comment: "",
                      is_client: true,
                      slug: chatId,
                    },
                    "POST"
                  );
                  this.setState({ submit_review: true });
                }}
                style={{
                  marginTop: 10,
                  width: "85%",
                  height: "50px",
                  backgroundColor: this.state.pointReady
                    ? "#7A63FF"
                    : "#C9C9C9",
                  borderWidth: 0,
                  borderRadius: 4,
                  color: "white",
                  fontSize: "1.2rem",
                }}
              >
                {strings.submit}
              </button>
            </div>
          </div>
        ) : (
          <div
            className="render-rate-us"
          >
            <div
              className="render-rate-us-inner"
            >
              <img
                className="header-logo"
                src={require("../../assets/images/rate_end_logo.png").default}
                alt=""
                style={{ width: 200, height: 200 }}
              />
              <span
                className="render-rate-us-inner-first-span"
              >
                {strings.thanks}
              </span>
              <span style={{ fontSize: 16, color: "#C9C9C9" }}>
                {strings.thanks_review}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderWaittingCustomer = (strings) => {
    return (
      <div
        className="render-waiting-customer"
      >
        <Loader type="Watch" color="#7A63FF" height={100} width={100} />
        <span style={{ color: "#7A63FF", fontSize: 26 }}>
          {strings.waiting_customer}
        </span>
      </div>
    );
  };

  renderAgoraCall = (strings, width, height) => {
    const { chatId } = this.props.match.params;
    const { selectedColor } = MeetingStore;
    // console.log("isMobile && (this.state.selectedType === drawing || !MeetingStore.showDrawer)", this.state.selectedType === "drawing" || MeetingStore.showDrawer)
    return (
      <AgoraVideoCall
        videoProfile={this.videoProfile}
        channel={this.channel}
        transcode={this.transcode}
        attendeeMode={this.attendeeMode}
        baseMode={this.baseMode}
        appId={this.appId}
        uid={this.uid}
        sendElementToUser={(x, y) => this.sendElementToUser(x, y, strings)}
      >
        {isMobile ? (
          <CanvasDraw
            hideGrid={true}
            hideInterface={true}
            disabled={true}
            ref={(r) => {
              this.draw = r;
              MeetingStore.setDrawer(r);
            }}
            brushColor={selectedColor}
            brushRadius={2}
            lazyRadius={8}
            style={{
              left: 0,
              backgroundColor: "transparent",
              zIndex: 5,
              position: "absolute",
              width: isMobile ? "100%" : width,
              height: isMobile ? "100%" : height,
            }}
          />
        ) : (
          <CanvasDraw
            hideGrid={true}
            hideInterface={false}
            disabled={this.state.selectedType !== "drawing"}
            ref={(r) => {
              this.draw = r;
              MeetingStore.setDrawer(r);
            }}
            brushColor={selectedColor}
            brushRadius={2}
            lazyRadius={8}
            style={{
              left: isMobile ? -30 : 0,
              backgroundColor: "transparent",
              zIndex: 1,
              position: "absolute",
              width: isMobile ? "100%" : width,
              height: isMobile ? "100%" : height,
            }}
          />
        )}
        <Elements />
        <RenderMessageBoxIconForMobile />
        <RenderEndCallForMobile onClick={() => {
          this.setState({ customer_end_call: true })
        }} />
        {isMobile && <RenderMessageBox chatId={chatId} />}
      </AgoraVideoCall>
    );
  };

  renderVideoEndLottie = (strings) => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationDone,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="render-video-lottie">
        <span style={{ fontSize: "2.2rem", color: customizeStore.primary, marginRight: 15, fontWeight: "bold" }}>
          {strings.ended_chat}
        </span>
      </div>
    );
  };

  renderVideoCall = (agMainStyle, width, height, end, strings) => {
    return (
      <div
        className="render-video-call"
      >
        <div className="ag-main" style={agMainStyle}>
          <div id={"ag-container-id"} className="ag-container">
            {!end &&
              (this.state.call.state === "waiting" ||
                this.state.call.state === "active") ? (
              isMobile ? (
                this.renderAgoraCall(strings, width, height)
              ) : (
                <div
                  id="main_cont_anil"
                  style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    width: width,
                    height: height,
                  }}
                >
                  {this.renderAgoraCall(strings, width, height)}
                  {!isMobile && this.renderTimer()}
                </div>
              )
            ) : this.state.showRating ? (
              this.renderRateUs(strings)
            ) : (
              this.renderVideoEndLottie(strings)
            )}
          </div>
        </div>
      </div>
    );
  };

  endLiveChat = (strings, customerNotConnect = false, resolved = false) => {
    confirmAlert({
      title: !customerNotConnect ? "" : strings.customer_not_connect_call,
      message: strings.end_call,
      buttons: [
        {
          label: strings.yes,
          onClick: async () => {
            const { chatId } = this.props.match.params;
            const { note, sparePart } = this.state;
            const resp = await sendRequest(
              "technicians/endCall",
              {
                id: chatId,
                note,
                resolved,
                sparePart,
                customerNotConnect,
              },
              "POST"
            );
            const payload = { type: "end", chatId };
            SocketStore.sendMessage(payload);
            customerNotConnect
              ? (window.location.href = "/home")
              : this.setState({ callEnd: true });
          },
        },
        {
          label: strings.no,
          onClick: () => { },
        },
      ],
    });
  };

  submitEndChat = async (
    resolved = true,
    customerNotConnect = false,
    isFinish
  ) => {
    const { chatId } = this.props.match.params;
    const { note, sparePart } = this.state;
    const resp = await sendRequest(
      "technicians/endCall",
      {
        id: chatId,
        note,
        resolved,
        sparePart,
        customerNotConnect,
      },
      "POST"
    );
    const payload = { type: "end", chatId };
    SocketStore.sendMessage(payload);
    isFinish
      ? (window.location.href = "/home")
      : this.setState({ callEnd: true });
    this.setState({ isEndCall: false });
  };

  renderEndLiveChatCustomer = () => {

    return (
      <Modal
        style={{ zIndex: 9999999999, width: "85%", top: "40%" }}
        isOpen={this.state.customer_end_call}
        toggle={() => this.setState({ customer_end_call: false })}
      >
        <ModalHeader toggle={() => this.setState({ customer_end_call: false })}>
          {this.state.call.company.name}
        </ModalHeader>
        <ModalBody>
          <p>{strings.are_u_sure_to_exit}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color={customizeStore.primary}
            style={{ background: customizeStore.primary, color: "white" }}
            onClick={() => {
              this.setState({ customer_end_call: false })
              SocketStore.sendMessage({ type: "end_call_customer" })
            }
            }
          >
            {strings.end_chat}
          </Button>
          <Button
            color="primary"
            outline
            style={{ borderColor: customizeStore.primary, color: customizeStore.primary }}
            onClick={() => this.setState({ customer_end_call: false })}
          >
            {strings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderEndLiveChat = () => {
    return (
      <Modal
        style={{ zIndex: 9999999999 }}
        isOpen={this.state.isEndCall}
        toggle={() => this.setState({ isEndCall: false })}
      >
        <ModalHeader toggle={() => this.setState({ isEndCall: false })}>
          <label>End Live Chat</label>
        </ModalHeader>
        <ModalBody>
          <p>{strings.end_call}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => this.submitEndChat(true, false, true)}
          >
            {strings.end_chat}
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => this.submitEndChat(true, false, false)}
          >
            {strings.end_chat_go_rating}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderEndCall = (strings) => {
    return (
      <div
        className="render-end-call"
      >
        <div
          className="render-end-call-inner"
        >
          <img
            className="header-logo"
            src={require("../../assets/images/check_mark.png").default}
            alt=""
            style={{ width: 135, height: 135, marginBottom: 40 }}
          />
          <span
            style={{
              fontSize: 28,
              color: "#7A63FF",
              fontWeight: "bold",
              marginTop: 46,
            }}
          >
            {strings.call_finished}
          </span>
          <span style={{ fontSize: 20, color: "#C9C9C9" }}>
            {strings.call_has_finished}
          </span>
          <span style={{ fontSize: 20, color: "#C9C9C9" }}>
            {strings.please_rate_us}
          </span>
        </div>
      </div>
    );
  };

  renderTechRate = (strings) => {
    return (
      <div
        className="render-tech-rate"
      >
        <div
          className="render-tech-rate-top"
        >
          <img
            className="header-logo"
            src={require("../../assets/images/rate_selffix_logo.png").default}
            alt=""
            style={{ width: 105, height: 85, marginTop: 40, marginBottom: 40 }}
          />
        </div>
        <div
          className="render-tech-rate-bottom"
        >
          <span
            style={{
              fontSize: 26,
              color: "#7A63FF",
              fontWeight: "bold",
              marginTop: 46,
            }}
          >
            {strings.rate_service}
          </span>
          <span style={{ fontSize: 16, color: "#C9C9C9" }}>
            {strings.technicians_rate_service}
          </span>
          <textarea
            placeholder={strings.add_note_review}
            onChange={(e) => this.setState({ rateNote: e.target.value })}
            className="render-tech-rate-bottom-textArea"
            name="message"
            rows="10"
            cols="30"
          ></textarea>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {this.state.techPoint.map((star, index) => {
              return this.starContainerTech(star, index);
            })}
          </div>
          <button
            disabled={!this.state.techPointReady}
            onClick={async () => {
              const pin = localStorage.getItem("pin");
              const { chatId } = this.props.match.params;
              let _point = 0;
              for (let i = 0; i < this.state.techPoint.length; i++) {
                if (this.state.techPoint[i] === true) {
                  _point += 1;
                }
              }
              const resp = await sendRequest(
                "rate",
                {
                  pin: pin,
                  rating: _point,
                  comment: this.state.rateNote,
                  is_client: false,
                  slug: chatId,
                },
                "POST"
              );
              if (resp.data.done) {
                this.setState({ tech_review: true });
              }
            }}
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "85%",
              height: "50px",
              backgroundColor: this.state.techPointReady
                ? "#7A63FF"
                : "#C9C9C9",
              borderWidth: 0,
              borderRadius: 4,
              color: "white",
              fontSize: "1.2rem",
            }}
          >
            {strings.submit}
          </button>
        </div>
      </div>
    );
  };

  renderTechReviewEnd = (strings) => {
    return (
      <div
        className="render-tech-review-end"
      >
        <div
          className="render-tech-review-end-top"
        >
          <img
            className="header-logo"
            src={require("../../assets/images/rate_selffix_logo.png").default}
            alt=""
            style={{ width: 85, height: 85, marginTop: 40, marginBottom: 40 }}
          />
        </div>
        <div
          className="render-tech-review-end-bottom"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                backgroundColor: "#FFFFFF",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: 50,
              }}
            >
              <img
                className="header-logo"
                src={require("../../assets/images/rate_end_logo.png")}
                alt=""
                style={{ width: 200, height: 200 }}
              />
              <span
                style={{
                  fontSize: 26,
                  color: "#7A63FF",
                  fontWeight: "bold",
                  marginTop: 46,
                }}
              >
                {strings.thanks}
              </span>
              <span style={{ fontSize: 16, color: "#C9C9C9" }}>
                {strings.thanks_review}
              </span>
            </div>
          </div>
          <a href={"/home"} style={{ width: "85%", height: "50px" }}>
            <button
              className="render-tech-review-end-bottom-btn"
            >
              {strings.leave_call}
            </button>
          </a>
        </div>
      </div>
    );
  };

  renderTimer = () => {
    const { dimensions, end, rtc, intervalId, meetingTimer } = MeetingStore;
    if (!this.state.intervalId) {
      // const intervalId = setInterval(() => {
      //   this.setState({ meetingTime: this.state.meetingTime + 1 })
      // }, 1000);
      // this.setState({intervalId});
      // meetingStore.startInterval()
      this.setState({ intervalId });
      this.setState({ meetingTime: meetingTimer });
    }
    let min = Math.floor(meetingTimer / 60);
    let sec = (meetingTimer % 60).toFixed(0);

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: -((dimensions.width - 8) / 2),
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          borderRadius: 4,
          width: 137,
          paddingRight: 12,
          paddingLeft: 12,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderRightWidth: 0.5,
              borderRightStyle: "solid",
              borderRightColor: "#ECECEC",
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <img
              className="header-logo"
              src={require("../../assets/images/time.png")}
              alt=""
              style={{
                width: 16,
                height: 16,
                marginRight: 12,
                color: "#B7AAFF",
              }}
            />
          </div>
          <div
            style={{
              width: 90,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#B7AAFF" }}>
              {min < 10 ? `0${min}` : min} : {sec < 10 ? `0${sec}` : sec}
            </span>
          </div>
        </div>
      </div>
    );
  };

  getMobileLang = async () => {
    const { chatId } = this.props.match.params;
    const resp = await sendRequest(
      "auth/lang",
      {
        id: chatId,
      },
      "POST"
    );
    this.setState({ mobileLang: resp.data.lang });
    // TechStore.setStrings(resp.data.lang);
  };

  render() {
    const { ready, call, pinEntered, callEnd, mobileLang, showRating } =
      this.state;
    const { chatId } = this.props.match.params;
    const { logo } = customizeStore;
    const { strings } = TechStore;
    // if(call && (call.state === "end" || call.state === "customer_not_connect")){
    //   let toUrl = `/call/${call.slug}`
    //   return <Redirect
    //     to={
    //       toUrl
    //     }
    //   />
    // }
    if (!showRating && callEnd) {
      return <Redirect to={"/home"} />;
    }
    if (callEnd) {
      return (
        <div
          className="wrapper meeting"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F5F5F5",
          }}
        >
          <div className="ag-header" style={{ backgroundColor: "white" }}>
            <div className="ag-header-lead">
              <img
                className="header-logo"
                src={logo ? logo : require("../../assets/images/loginIcon.png")}
                alt=""
                style={{ width: "120px", height: "35px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            {this.renderToolBar(strings)}
            {this.renderEndCall(strings)}
            {this.state.tech_review
              ? this.renderTechReviewEnd(strings)
              : this.renderTechRate(strings)}
          </div>
        </div>
      );
    }
    if (isMobile && !mobileLang) {
      this.getMobileLang();
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loader type="Puff" color="#7A63FF" height={100} width={100} />
        </div>
      );
    }
    if (!pinEntered) {
      return <PinEnter chatId={chatId} pin={this.sendPin} />
    }

    if (!ready) return <div></div>;

    const { dimensions, end } = MeetingStore;
    let width, height;

    if (dimensions) {
      width = dimensions.width + "px";
      height = dimensions.height + "px";
    }

    const agMainStyle = isMobile
      ? { display: "flex", flex: 1, height: "100%" }
      : { width, height };

    const pin = localStorage.getItem("pin");

    const { rtc } = meetingStore;

    return (
      <div
        className="wrapper meeting"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F5F5F5",
        }}
      >
        {this.renderEndLiveChat()}
        {this.renderEndLiveChatCustomer()}
        <div className="ag-header" style={{ backgroundColor: "white" }}>
          <div className="ag-header-lead">
            <img
              className="header-logo"
              src={logo ? logo : require("../../assets/images/loginIcon.png")}
              alt=""
              style={{ width: "120px", height: "35px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", flex: 1, }}>
          {rtc && this.renderToolBar(strings)}
          {(dimensions || isMobile || !localStorage.getItem("user")) ? this.renderVideoCall(agMainStyle, width, height, end, strings) : this.renderWaittingCustomer(strings)}
          {this.renderTechForm(strings)}
        </div>
        {!isMobile && (
          <RenderScreenShoot
            addFileToNote={(url) => {
              const { note } = this.state;
              let new_note;
              if (note) {
                new_note = note + "\n\nNew screenshot: " + url;
              } else {
                new_note = "New screenshot: " + url;
              }

              this.setState({ note: new_note });
            }}
            chatId={chatId}
          />
        )}
        <ShowFile />
      </div>
    );
  }
}

export default observer(Meeting);
observer(PinEnter);
