import React from 'react'

import { observer } from 'mobx-react'
import MeetingStore from "../../stores/meetingStore";
import screenshotStore from '../../stores/screenshotStore';

const circle = require("../../assets/images/circle-selected.png");
const up_arrow = require("../../assets/images/up-arrow-selected.png");
const right_arrow = require("../../assets/images/right-arrow-selected.png");
const bottom_arrow = require("../../assets/images/bottom-arrow-selected.png");
const left_arrow = require("../../assets/images/left-arrow-selected.png");

const white_circle = require("../../assets/images/white-circle-selected.png");
const white_up_arrow = require("../../assets/images/white-up-arrow-selected.png");
const white_right_arrow = require("../../assets/images/white-right-arrow-selected.png");
const white_bottom_arrow = require("../../assets/images/white-bottom-arrow-selected.png");
const white_left_arrow = require("../../assets/images/white-left-arrow-selected.png");

const red_circle = require("../../assets/images/red-circle-selected.png");
const red_up_arrow = require("../../assets/images/red-up-arrow-selected.png");
const red_right_arrow = require("../../assets/images/red-right-arrow-selected.png");
const red_bottom_arrow = require("../../assets/images/red-bottom-arrow-selected.png");
const red_left_arrow = require("../../assets/images/red-left-arrow-selected.png");


const black_circle = require("../../assets/images/black-circle-selected.png");
const black_up_arrow = require("../../assets/images/black-up-arrow-selected.png");
const black_right_arrow = require("../../assets/images/black-right-arrow-selected.png");
const black_bottom_arrow = require("../../assets/images/black-bottom-arrow-selected.png");
const black_left_arrow = require("../../assets/images/black-left-arrow-selected.png");



class Elements extends React.Component {

  render(){
    const { elements } = screenshotStore;
    const { selectedColor } = MeetingStore;
    if(elements.length === 0) return null;
    
    let _circle, _up_arrow, _right_arrow, _bottom_arrow, _left_arrow;

    switch(selectedColor){
      case "black": {
        _circle = black_circle;
        _up_arrow = black_up_arrow;
        _bottom_arrow = black_bottom_arrow;
        _right_arrow = black_right_arrow;
        _left_arrow = black_left_arrow;
        break;
      }
      case "white": {
        _circle = white_circle;
        _up_arrow = white_up_arrow;
        _bottom_arrow = white_bottom_arrow;
        _right_arrow = white_right_arrow;
        _left_arrow = white_left_arrow;
        break;
      }
      case "#DB3636": {
        _circle = red_circle;
        _up_arrow = red_up_arrow;
        _bottom_arrow = red_bottom_arrow;
        _right_arrow = red_right_arrow;
        _left_arrow = red_left_arrow;
        break;
      }
      default : {
        _circle = circle;
        _up_arrow = up_arrow;
        _bottom_arrow = bottom_arrow;
        _right_arrow = right_arrow;
        _left_arrow = left_arrow;
      }
    }
    
    return(
      <div style={{position: 'absolute',left:0, right:0, bottom:0, top:0 }}>
        {elements.map((e,index) => {
          let src;
          let width = 50;
          let height = 50;
          let left = e.x-15;
          let top = e.y-25;
          switch(e.selectedType){
            case "bottom-arrow":{
              src = _bottom_arrow;
              width = 25;
              top = top-25;
              break;
            }
            case "left-arrow":{
              src = _left_arrow;
              height = 25;
              top = top + 10;
              left = left + 15;
              break;
            }
            case "right-arrow":{
              src = _right_arrow;
              height = 25;
              top = top + 10;
              left = left - 35;
              break;
            }
            case "up-arrow":{
              src = _up_arrow;
              width = 25;
              top = top+25;
              break;
            }
            case "circle":{
              src = _circle;
              left = left - 10;
              break;
            }
          }
          return <img key={"element"+"#"+index} src={src} style={{position: 'absolute', left, top, width, height, zIndex:this.props.zIndex }}/>
        })}
        
      </div>
    )
  }

}

export default observer(Elements);