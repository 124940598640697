import React, { Component } from 'react'
import { BrowserRouter as Router, Route, useParams, Redirect } from 'react-router-dom'
import techStore from '../stores/techStore';
import { MessageBox } from "react-chat-elements";

import { observer } from 'mobx-react';

import moment from "moment"

import { saveAs } from 'file-saver';
import strings from '../helpers/localization';
import '../assets/styles/callDetail.css'

const Messages = observer(({ messages }) => {

  return (
    <div className='messages-inner-container'>
      {messages.map((m, i) => {
        const isMy = m.from === "technician";
        const className = isMy ? 'my-messages-style' : 'other-messages-style';
        let _type;
        let _c = "";
        var _m = m.message.content.toString().match(/.*\/(.+?)\./);
        if (_m && _m.length > 1) {
          _c = _m[1];
        }
        if (m.message.type !== undefined) {
          if (m.message.type === "text") {
            _type = true
          } else {
            _type = false;
          }
        } else {
          if (m.message.content.includes("http") || m.message.content.includes("https")) {
            _type = false;
          } else {
            _type = true;
          }
        }

        return (
          <div key={i + "messagesBox"} className='message-box' style={{ alignSelf: "stretch", alignSelf: "stretch", display: "flex", flexDirection: "row", justifyContent: isMy ? "flex-end" : "flex-start" }}>
            <div className={className}>
              {_type ? <span style={{ color: isMy ? "white" : "black", fontSize: "1.2rem" }}>
                {m.message.content}
              </span> : (m.message.content.includes("pdf")) ? <div style={{ color: !isMy ? "black" : "white" }}><a style={{ color: isMy ? "white" : "black" }} target="_blank" href={m.message.content}>{_c}</a></div> :
                <div style={{ color: !isMy ? "black" : "white" }} onClick={() => {

                }}><img src={m.message.content} />

                </div>}
              <div style={{ marginTop: 3, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {!_type && <div style={{ color: "white", fontSize: "1rem" }}>
                  <button onClick={() => {
                    saveAs(m.message.content, _c, { type: "image/jpeg" });
                  }} style={{ border: 0, borderRadius: 5, backgroundColor: "white" }}>
                    {strings.download}
                  </button>
                </div>}
                <span style={{ color: isMy ? "white" : "black", fontSize: "0.65rem" }}>
                  {moment(m.createdAt).format("HH:mm")}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
})

class Tech extends Component {
  constructor(props) {
    super(props)

    this.state = {
      resolved: false,
      state: "",
      note: strings.notes,
      sparePart: strings.spare_part,
    }
  }

  async componentDidMount() {
    await techStore.getCall(this.props.match.params.callId);
    this.handleData();
  }

  handleSave = async () => {
    const { call } = techStore;
    const {
      note,
      sparePart,
      state,
      resolved,
    } = this.state;

    const _call = {
      "call_id": call._id,
      "note": note,
      "state": state,
      "sparePart": sparePart,
      "resolved": resolved,
    };

    console.log(_call);
    try {
      await techStore.editCall(_call);
      alert(strings.changes_saved);
    } catch (error) {
      console.log("error => ", error);
    }
  }

  handleData() {
    const { call } = techStore;
    this.setState({
      resolved: call.resolved,
      sparePart: call.sparePart,
      note: call.note,
      state: call.state,
    })

  }



  renderContent = (strings) => {
    const { call, duration, messages } = techStore;
    const selectedColor = '#7A63FF';
    const defaultColor = '#E6E6E6';
    return (
      <div className='render-content'>
        <div className='render-content-inner'>
          <div className='render-content-header' >
            <div className='render-content-header-box'>
              <span className='render-content-header-box-label' >ID:</span>
              <span className='render-content-header-box-value' >{call._id}</span>
            </div>
            <div className='render-content-header-box'>
              <span className='render-content-header-box-label' >{strings.customer}:</span>
              <span className='render-content-header-box-value' >{call.customer}</span>
            </div>
            <div className='render-content-header-box'>
              <span className='render-content-header-box-label' >{strings.technician}:</span>
              <span className='render-content-header-box-value' >{call.technician}</span>
            </div>
            {duration && <div className='render-content-header-box' style={{ marginRight: 150 }}>
              <span className='render-content-header-box-label' >{strings.duration}:</span>
              <span className='render-content-header-box-value' >{duration.minutes + " mins " + Math.ceil(duration.seconds) + " sec"}</span>
            </div>}
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>state</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{this.state.state}</span>
            </div> */}
          </div>
          <div className='render-content-body' >
            <div>
              <span className='render-content-body-box-label'>{strings.resolved}:</span>
              <div className='render-content-body-select-box'>
                <div onClick={() => this.setState({ resolved: true })}>
                  <div style={{ borderColor: this.state.resolved ? selectedColor : defaultColor }}>
                    {this.state.resolved && <div />}
                  </div>
                  <span style={{ color: this.state.resolved ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.resolved}</span>
                </div>
                <div onClick={() => this.setState({ resolved: false })}>
                  <div style={{ borderColor: !this.state.resolved ? selectedColor : defaultColor }}>
                    {!this.state.resolved && <div />}
                  </div>
                  <span style={{ color: !this.state.resolved ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.not_resolved}</span>
                </div>
              </div>
            </div>
            <div>
              <span className='render-content-body-box-label'>state:</span>
              <div className='render-content-body-select-box'>
                <div onClick={() => this.setState({ state: "active" })}>
                  <div style={{ borderColor: this.state.state === "active" ? selectedColor : defaultColor }}>
                    {this.state.state === "active" && <div />}
                  </div>
                  <span style={{ color: this.state.state === "active" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.active}</span>
                </div>
                <div style={{ width: 200 }} onClick={() => this.setState({ state: "customer_not_connect" })}>
                  <div style={{ borderColor: this.state.state === "customer_not_connect" ? selectedColor : defaultColor }}>
                    {this.state.state === "customer_not_connect" && <div />}
                  </div>
                  <span style={{ color: this.state.state === "customer_not_connect" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.customer_is_not_connect}</span>
                </div>
                <div onClick={() => this.setState({ state: "end" })}>
                  <div style={{ borderColor: this.state.state === "end" ? selectedColor : defaultColor }}>
                    {this.state.state === "end" && <div />}
                  </div>
                  <span style={{ color: this.state.state === "end" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.end}</span>
                </div>
              </div>
            </div>
            <div>
              <span className='render-content-body-box-label'>{strings.note}</span>
              <div className='render-content-body-textArea-box'>
                <textarea value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} name="message" rows="10" cols="30"></textarea>
              </div>
            </div>
            <div>
              <span className='render-content-body-box-label'>{strings.spare_part}</span>
              <div className='render-content-body-textArea-box'>
                <textarea value={this.state.sparePart} onChange={(e) => this.setState({ sparePart: e.target.value })} name="message" rows="10" cols="30"></textarea>
              </div>
            </div>
            <div className='render-content-body-btns'>
              <div className='cancel-btn' onClick={() => this.endLiveChat(false, false)} >
                {strings.cancel}
              </div>
              <div className='save-btn' onClick={() => this.handleSave()} >
                {strings.save}
              </div>
            </div>
          </div>
        </div>
        {messages.length > 0 &&
          <div className='messages-container' >
            <div className='messages-container-header'>
              <div>
                <span>{strings.messages}</span>
              </div>
            </div>
            <div className='messages-container-body' >
              <Messages messages={messages} />
              {/* <div>
                {messages.map(item => {
                  let type;
                  if(item.message.type==="file"){
                    if(item.message.content.includes(".pdf")){
                      type="file"
                    }else{
                      type="photo"
                    }
                  }else{
                    type="text";
                  }
                  if(item.from === "technician"){
                    return(
                    <MessageBox title={'Technician'} position={'left'} 
                    date={new Date(item.createdAt)} text={item.message.type==="file"?null:item.message.content}
                    type={type}
                    onClick={ () => {
                      if(type === "file"){
                        window.open(item.message.content, "_blank")
                      }
                    }}
                    data={{uri:item.message.content}} titleColor={'#7A63FF'} />)
                  }else if(item.from==="customer"){
                    return(
                    <MessageBox title={'Customer'} position={'right'}
                    date={new Date(item.createdAt)} text={item.message.type==="file"?null:item.message.content}
                    type={type}
                    onClick={ () => {
                      if(type === "file"){
                        window.open(item.message.content, "_blank")
                      }
                    }}
                    data={{uri:item.message.content}}  />)
                  }
                })}
              </div> */}
            </div>
          </div>}
      </div>

    )
  }

  renderLeft = (strings) => {
    const { selectedState } = this.state;

    return (
      <div className='render-left-container'>
        <div className={selectedState === "customer_list" ? 'render-left-selected-element render-left-elemet' : 'render-left-unselected-element render-left-elemet'} onClick={() => this.setState({ selectedState: "customer_list" })}>
          <img
            src={require("../assets/images/customer_list.png")}
            alt=""
          />
          <span><a href="/home">{strings.customer_list}</a></span>
        </div>
        <div className='log-out-container' onClick={() => this.logoutModal(strings)}>
          <img
            src={require("../assets/images/logout.png").default}
            alt=""
          />
          <span>{strings.logout}</span>
        </div>
      </div>
    )
  }

  render() {
    const { pathname, calls } = this.state;
    const { strings } = techStore;
    const type = this.props.location.pathname.split("/")
    if (pathname) {
      return (
        <Redirect
          to={{
            pathname,
          }}
        />
      )
    }

    return (
      <div className='callDetail-container' >
        <div className="ag-header" >
          <div className="ag-header-lead" >
            <img
              className="header-logo"
              src={require("../assets/images/loginIcon.png")}
              alt=""
              style={{ width: "120px", height: "35px", marginLeft: 16 }}
            />
          </div>
        </div>
        <div className='callDetail-inner-container'>
          {this.renderLeft(strings)}
          {this.renderContent(strings)}
        </div>
      </div>
    )
  }
}

export default observer(Tech);

