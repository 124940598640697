import { observable, action, computed, makeObservable } from 'mobx';
import SocketStore from "./socketStore";
import * as MobileCheck from "react-device-detect";

const isMobile = MobileCheck.isMobile || !localStorage.getItem("user");

class MessageStore {

  constructor() {
    makeObservable(this, {
      messages: observable,
      messageText: observable,
      showMessageBox: observable,
      scrollContainer: observable,
      showBadge: observable,
      setShowBadge: action,
      setMessageText: action,
      insertNewMessage: action,
      setMessages: action,
      setShowMessageBox: action,
      setScrollContainer: action,
      showFile: observable,
      setShowFile: action,
    });
  }

  messages = [];
  scrollContainer = null;
  messageText = "";
  showMessageBox = false;
  showFile = null;
  showBadge = false;

  setShowFile(showFile) {
    this.showFile = showFile;
  }

  setMessages(messages) {
    if (this.messages.length === 0) {
      this.messages = messages;
      this.scrollToEnd()
    }
  }

  setScrollContainer(scrollContainer) {
    this.scrollContainer = scrollContainer
  }

  scrollToEnd(ms = 1000) {
    console.log("🚀 ~ file: messageStore.js ~ line 41 ~ MessageStore ~ scrollToEnd ~ this.scrollContainer", this.scrollContainer)
    if (this.scrollContainer) {
      setTimeout(() => {
        console.log("GELDI");
        this.scrollContainer.scrollIntoView({ behavior: "smooth" })
      }, ms);
    }
  }

  insertNewMessage(content, type) {
    const message = {
      from: isMobile ? "customer" : "technician",
      createdAt: new Date(),
      message: {
        content,
        type
      }
    }
    this.setShowBadge(true);
    this.messages.push(message);
    this.scrollToEnd(200)
  }

  setShowMessageBox(showMessageBox) {
    this.showMessageBox = showMessageBox;
    this.setShowBadge(false);
    if (showMessageBox) {
      this.scrollToEnd(1000)
    }
  }

  insertNewMessageFromRemote(content, type, date) {
    const message = {
      from: !isMobile ? "customer" : "technician",
      createdAt: new Date(date),
      message: {
        content,
        type
      }
    }
    this.setShowBadge(true);
    this.messages.push(message);
    this.scrollToEnd(200)
  }

  setMessageText(messageText) {
    this.messageText = messageText;
  }

  setShowBadge(showBadge) {
    if (!this.showMessageBox) {
      this.showBadge = showBadge
    }
  }

  sendMessage = async (chatId, _type = "text", content = "") => {
    const payload = {
      type: "message",
      chatId,
      message: {
        type: _type, //text - file
        content: _type === "text" ? this.messageText : content
      },
    }
    if (_type === "text" && this.messageText === "") {
      return null;
    }
    this.insertNewMessage(payload.message.content, _type)
    this.setMessageText("");
    await SocketStore.sendMessage(payload, !isMobile);
  }

}


const messageStore = new MessageStore();
export default messageStore;