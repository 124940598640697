import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter as Router, Route, useParams, Switch, Redirect } from 'react-router-dom'
import 'bulma/css/bulma.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'
import Index from './index'
import Meeting from './meeting'
import LoginScreen from "./login";
import HomeScreen from "./home";
import TechScreen from "./tech";
import TechStore from "../stores/techStore";
import CallDetailScreen from './callDetail';
import strings from '../helpers/localization';

class App extends Component {
  render() {
    const { user } = TechStore
    const lang = strings.getInterfaceLanguage()
    strings.setLanguage(lang);
    if (user) {
      return (
        <Router>
          <Switch>
            <Route path="/home" component={TechScreen} />
            <Route path="/call/:callId" component={CallDetailScreen} />
            <Route path="/live/:chatId" component={Meeting} />
            <Redirect from="*" to="/home" />
          </Switch>
        </Router>
      )
    }
    return (
      <Router>
        <Switch>
          <Route path="/live/:chatId" component={Meeting} />
          <Route path="/" component={LoginScreen} />
        </Switch>
      </Router>
    )
  }
}

export default observer(App)
