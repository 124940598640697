import { observable, action, computed, makeObservable } from 'mobx';
import { useRef } from 'react';
import { instance } from '../helpers/axiosWrapper';
import html2canvas from 'html2canvas'

import { D_O_S_A, D_O_S_S } from "../agora.config";

import AWS from "aws-sdk";
import * as MobileCheck from 'react-device-detect';

const isMobile = MobileCheck.isMobile || !localStorage.getItem("user");

const spacesEndpoint = new AWS.Endpoint("fra1.digitaloceanspaces.com");

const s3 = new AWS.S3({
  accessKeyId: D_O_S_A,
  secretAccessKey: D_O_S_S,
  endpoint: spacesEndpoint
});

class ScreenshotStore {

  constructor() {
    makeObservable(this, {
      tracker: observable,
      showScScreen: observable,
      sc: observable,
      isDrawed: observable,
      showLoading: observable,
      setShowLoading: action,
      elements: observable,
      drawer: observable,
      setDrawer: action,
      insertElement: action,
      clearElemets: action,
      selectedType: observable,
      setSelectedType: action,
      setSc: action,
      clean: action,
      setShowScScren: action,
      setTracker: action
    });
  }

  tracker = null;
  sc = null;
  selectedType = null;
  showScScreen = false;
  elements = [];
  cords = null;
  drawer = null;
  torch_isopen = false;
  isDrawed = false;
  showLoading = false;

  setTracker(tracker) {
    this.tracker = tracker;
  }

  setDrawer(drawer) {
    if (drawer) {
      this.drawer = drawer;
    }
  }

  setShowLoading(showLoading) {
    this.showLoading = showLoading
  }

  torch() {
    if (!isMobile) return null;
    const tracker = this.tracker.getMediaStreamTrack();
    console.log("🚀 ~ file: screenshotStore.js ~ line 57 ~ ScreenshotStore ~ torch ~ tracker", tracker)
    const consts = tracker.getConstraints();
    console.log("🚀 ~ file: screenshotStore.js ~ line 54 ~ ScreenshotStore ~ torch ~ consts", consts)
    if (consts && consts.advanced) {
      if (this.torch_isopen) {
        this.torch_isopen = false;
        tracker.applyConstraints({
          facingMode: "environment",
          advanced: [{ torch: false }]
        })
      } else {
        this.torch_isopen = true;
        tracker.applyConstraints({
          facingMode: "environment",
          advanced: [{ torch: true }]
        })
      }
    } else {
      this.torch_isopen = true;
      tracker.applyConstraints({
        facingMode: "environment",
        advanced: [{ torch: true }]
      })
    }
    // console.log("🚀 ~ file: index.js ~ line 48 ~ Torch ~ m_s_t", m_s_t)
  }

  clean() {
    this.elements = [];
    this.selectedType = null;
    console.log(this.drawer)
    this.drawer.clear();
    this.isDrawed = false;
  }

  setSelectedType(selectedType) {
    if (selectedType === "drawing") {
      this.isDrawed = true;
    }
    this.selectedType = selectedType;
  }

  insertElement(element) {
    this.elements.push(element);
  }

  clearElemets() {
    this.elements = [];
  }

  takeScreenshot = async () => {
    if (!this.tracker) return null;
    const data = await this.tracker.getCurrentFrameData();

    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = data.width;
    canvas.height = data.height;
    ctx.putImageData(data, 0, 0);

    this.setSc(canvas);
    this.setShowScScren(true);

    // const img = document.createElement("img");

    // img.src = canvas.toDataURL();
    // img.style.width = "640px";
    // img.style.height = "480px";
    // img.style.position = "absolute";
    // img.style.top = "0px";
    // img.style.left = "0px";

    // document.body.append(img);
  }

  setShowScScren(showScScreen) {
    this.showScScreen = showScScreen;
    if (!showScScreen) {
      this.setSc(null);
    }
  }

  setSc(sc) {
    this.sc = sc;
  }

  handleMouse = (e) => {
    this.cords = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
  }

  sendSc = (_doc) => {
    return new Promise((resolve) => {
      html2canvas(_doc).then(async canvas => {
        canvas.toBlob(async (blobData) => {
          const params = {
            Key: `${new Date().getTime()}.jpeg`,
            Body: blobData,
            ACL: "public-read",
            Bucket: "selffix",
            ContentType: `image/jpeg`, // required. Notice the back ticks
          };
          const resp = await s3.upload(params).promise();
          resolve(resp.Location);
        });
      })
    })
  }

  onClick = () => {
    const { cords, selectedType } = this;
    if (!selectedType || selectedType === "drawing") return;
    // console.log("🚀 ~ file: screenshotStore.js ~ line 84 ~ ScreenshotStore ~ onClick ~ this", this)

    const { x, y } = cords;
    const element = {
      x, y, selectedType
    }
    this.insertElement(element);
    this.setSelectedType(null);
  }

}


const screenshotStore = new ScreenshotStore();
export default screenshotStore;