import React, { Component } from 'react'
import { BrowserRouter as Router, Route, useParams, Redirect } from 'react-router-dom'
import { sendRequest } from "../../helpers/axiosWrapper";
import strings from '../../helpers/localization';

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
     phone:"",
     pathname:null
    }
  }
  render() {
    const { pathname } = this.state;
    if(pathname){
      return (
        <Redirect
            to={{
              pathname,
            }}
          />
      )
    }
    
    return (
      <div style={{display:"flex",flex:1,flexDirection:"column",justifyContent:"center",alignItems:"center",height:"100%"}}>
        <input onChange={ (e)=> {
          this.setState({phone:e.target.value})
        }} style={{width:"300px",height:"50px"}} placeholder="Phone Number" type="email" name="email" />
        <button onClick={ async ()=> {
          const resp = await sendRequest("technicians/createCall",this.state,"POST");
          const { path, pin } = resp.data.call;
          await localStorage.setItem("pin",pin);
          this.setState({pathname:path});
        }} style={{marginTop:10,width:"300px",height:"50px"}}>
          {strings.start_call}
        </button>
      </div>
    )
  }
}

export default Home;
