import React, { Component } from 'react'
import { BrowserRouter as Router, Route, useParams, Redirect, Link } from 'react-router-dom'
import { sendRequest } from "../helpers/axiosWrapper";
import { confirmAlert } from 'react-confirm-alert';
import moment from "moment";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import techStore from '../stores/techStore';
import { Dropdown } from 'react-bootstrap';

import {
  Button,
  Card,
  CardHeader,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import ReactExport from "react-export-excel";
import Loader from "react-loader-spinner";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { observer } from 'mobx-react';
import strings from '../helpers/localization';
import "../assets/styles/tech.css"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class Tech extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedState: "customer_list",
      calls: [],
      phone: "",
      pathname: null
    }
  }

  async componentDidMount() {
    setTimeout(async () => {
      await techStore.getCalls()
    }, 1000);
    // sendRequest("technicians").then((resp) => {
    //   this.setState({ calls: resp.data.calls });
    // })
  }

  renderSearchBar = (strings) => {
    const { filter_text } = techStore
    return (
      <div>
        <span className='search-label' >{strings.search}</span>
        <div>
          <input
            className='search-bar-input'
            value={filter_text}
            onChange={(e) => {
              techStore.setTextFilter(e.target.value)
            }} type="text" name="search" />
        </div>
      </div>
    )
  }

  renderStatusSelector = (strings) => {
    const { selected_status_text } = techStore

    return (
      <UncontrolledDropdown direction="down">
        <div>
          <span className='search-label'>{strings.status}</span>
          <DropdownToggle
            caret
            className="btn-block"
            color="primary"
            data-toggle="dropdown"
            type="button"
            style={{ height: 45, backgroundColor: '#7A63FF' }}
          >
            {selected_status_text}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={async (e) => { techStore.setStatusType("All", strings.all) }}
            >
              {strings.all}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => { await techStore.setStatusType("end", strings.end) }}
            >
              {strings.end}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => {
                await techStore.setStatusType("customer_not_connect", strings.customer_not_connect)
              }}
            >
              {strings.customer_not_connect}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => {
                await techStore.setStatusType("active", strings.active)
              }}
            >
              {strings.active}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => {
                await techStore.setStatusType("waiting", strings.waiting)
              }}
            >
              {strings.waiting}
            </DropdownItem>
          </DropdownMenu>
        </div>
      </UncontrolledDropdown>
    )
  }

  renderResolveSelector = (strings) => {
    const { selected_resolve_status } = techStore
    return (
      <UncontrolledDropdown direction="down">
        <div>
          <span className='search-label'>{strings.resolve_status}</span>
          <DropdownToggle
            caret
            className="btn-block"
            color="primary"
            data-toggle="dropdown"
            type="button"
            style={{ height: 45, backgroundColor: '#7A63FF' }}
          >
            {typeof selected_resolve_status == "string" ? selected_resolve_status : selected_resolve_status ? strings.resolved : strings.not_resolved}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={async (e) => { techStore.setResolveType("All") }}
            >
              {strings.all}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => { techStore.setResolveType(true) }}
            >
              {strings.resolved}
            </DropdownItem>
            <DropdownItem
              onClick={async (e) => {
                techStore.setResolveType(false)
              }}
            >
              {strings.not_resolved}
            </DropdownItem>
          </DropdownMenu>
        </div>
      </UncontrolledDropdown>
    )
  }

  renderDatePicker = (strings) => {
    const { selected_start_date } = techStore
    return (
      <div>
        <span className='search-label'>{strings.min_date}</span>
        <ReactDatetime
          inputProps={{
            placeholder: strings.min_date,
            style: { height: 45, borderColor: '#C9C9C9', borderRadius: 5 },

          }}
          timeFormat={false}
          onChange={(e) => techStore.setStartDate(e)}
          closeOnSelect
          dateFormat={"DD/MM/YYYY"}
          renderInput={(props) => {
            return <input {...props} value={(selected_start_date !== '') ? props.value : ''} />
          }}
        />
      </div>
    )
  }

  renderDatePicker2 = (strings) => {
    const { selected_end_date } = techStore

    return (
      <div>
        <span className='search-label'>{strings.max_date}</span>
        <ReactDatetime
          inputProps={{
            placeholder: strings.max_date,
            style: { height: 45, borderColor: '#C9C9C9', borderRadius: 5 },

          }}
          timeFormat={false}
          onChange={(e) => techStore.setEndDate(e)}
          closeOnSelect
          dateFormat={"DD/MM/YYYY"}
          value={selected_end_date}
          renderInput={(props) => {
            return <input {...props} value={(selected_end_date !== '') ? props.value : ''} />
          }}
        />
      </div>
    )
  }

  renderDuration = (cell, row) => {
    if (row) {
      return (
        <span>
          {row.duration ? row.duration.minutes < 10 ? `0${row.duration.minutes}` : row.duration.minutes : "-"}:{row.duration ? row.duration.seconds.toFixed(0) < 10 ? `0${row.duration.seconds.toFixed(0)}` : row.duration.seconds.toFixed(0) : "-"}
        </span>
      );
    }
  }

  renderStatus = (cell, row) => {
    // #B81D13 #EFB700 #008450 #7A63FF
    console.log(cell)
    let st;
    let backgroundColor;
    switch (cell) {
      case "waiting": {
        st = strings.waiting;
        backgroundColor = "#008450"
        break;
      }
      case "customer_not_connect": {
        st = strings.customer_not_connect;
        backgroundColor = "#B81D13"
        break;
      }
      case "active": {
        st = strings.active;
        backgroundColor = "#7A63FF"
        break;
      }
      case "end": {
        st = strings.end;
        backgroundColor = "#EFB700"
        break;
      }
    }
    return (
      <span style={{ padding: 3, paddingLeft: 5, paddingRight: 5, borderRadius: 5, backgroundColor, color: "white" }}>
        {st}
      </span>
    )
  }

  renderResolved = (cell, row) => {

    const sparePartIcomn = require("../assets/images/information.png").default;
    const isSparePart = row.sparePart != "" && row.sparePart != null && row.sparePart != undefined;

    const sparePart = (isSparePart ? <div className='sparePart' ><img src={sparePartIcomn} /> <div className='sparePartDesc' >{strings.spare_part_waiting}<b>{row.sparePart}</b></div> </div> : null);

    const src = cell ? require("../assets/images/checked_true.png") : require("../assets/images/checked_false.png");

    return <div> {row.state === "end" ? <img src={src} style={{ width: "20px", height: "20px" }} /> : <span>-</span>}{sparePart}</div>
  }

  renderId = (cell, row) => {
    return (
      <Link to={`/call/${row.slug}`}>
        {row.slug}
      </Link>
    );
  }

  renderCreatedAt = (cell) => {
    return (
      <span>
        {moment(cell).format('DD/MM/YYYY HH:mm')}
      </span>
    )
  }

  renderActions = (cell, row) => {
    const { strings, calls, call_request } = techStore;
    if (row.state === "end" || row.state === "customer_not_connect") {
      return null
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle style={{ backgroundColor: "#7A63FF", borderColor: "#7A63FF" }} id="dropdown-basic">
            {strings.call_actions}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {row.state === "active" && <Dropdown.Item onClick={async () => {

              const { call } = await this.callActions(row.slug, true, false);


              const _index = calls.findIndex((c) => c.slug === row.slug);
              const _calls = calls;
              _calls[_index] = call;
              this.setState({
                calls: _calls
              });
            }}>{strings.resolve}</Dropdown.Item>
            }
            <Dropdown.Item onClick={async () => {
              const _call = await sendRequest("technicians/call", {
                slug: row.slug
              }, "POST");
              const { path, pin } = _call.data.call;
              await localStorage.setItem("pin", pin);
              // onClose();
              this.setState({ pathname: path });
            }}>{strings.connect_the_call}</Dropdown.Item>
            <Dropdown.Item onClick={async () => {
              const { call } = await this.callActions(row.slug, false, true);
              const _index = calls.findIndex((c) => c.slug === row.slug);
              const _calls = calls;
              _calls[_index] = call;
              this.setState({
                calls: _calls
              });
            }}>{strings.customer_is_not_connect}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  }

  renderCalls = (strings) => {
    const { calls } = techStore;
    console.log('calls: ', calls.slice(0, 5));
    const { features } = techStore;
    if (!features.core) return (
      <div className='render-calls-container'>
        {strings.no_access}
      </div>
    )
    return (
      <div className='render-calls-container'>
        <div className='render-calls-inner-container'>
          <div className='render-calls-top-bar'>
            <span>{strings.customer_list}</span>
            {this.renderExcel(strings)}
          </div>
          <div
            id="datatable-basic_filter"
            className="dataTables_filter"
          >
            {this.renderSearchBar(strings)}
            {this.renderStatusSelector(strings)}
            {this.renderResolveSelector(strings)}
            {this.renderDatePicker(strings)}
            {this.renderDatePicker2(strings)}
            <Button
              style={{ backgroundColor: "#7A63FF", borderWidth: 0, height: 45 }}
              color="default"
              type="button"
              onClick={() => {
                techStore.resetFilter()
              }}
            >
              <span style={{ color: 'white' }}>{strings.clear_filters}</span>
            </Button>
          </div>
          <div style={{ marginTop: 12 }}>
            <ToolkitProvider
              data={calls.slice()}
              keyField="_id"
              columns={[
                {
                  dataField: "id",
                  text: strings.id,
                  formatter: this.renderId
                },
                {
                  dataField: "customer",
                  text: strings.customer,
                },
                {
                  dataField: "duration",
                  text: strings.duration,
                  formatter: this.renderDuration
                },
                {
                  dataField: "state",
                  text: strings.status,
                  formatter: this.renderStatus
                },
                {
                  dataField: "resolved",
                  text: strings.resolved,
                  formatter: this.renderResolved
                },
                {
                  dataField: "createdAt",
                  text: strings.createdAt,
                  formatter: this.renderCreatedAt
                },
                {
                  dataField: "slug",
                  text: strings.actions,
                  formatter: this.renderActions
                },

                // {
                //     dataField: "is_blocked",
                //     text: "License Plate",
                //     formatter: this.renderPlate
                // },
              ]}
            >
              {(props) => (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                />
              )}
            </ToolkitProvider>
            {/* {calls.slice().map((item) => {
                let src = item.resolved ? require("../assets/images/checked_true.png") : require("../assets/images/checked_false.png");
                return (
                  <tr style={{ backgroundColor: "white", boxShadow: "0px 0px 3px #C6C6C6", marginTop: "15px" }}>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}><Link to={`/call/${item.slug}`}>{item.slug}</Link></td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>{item.customer}</td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>{item.duration ? item.duration.minutes < 10 ? `0${item.duration.minutes}` : item.duration.minutes : "-"}:{item.duration ? item.duration.seconds.toFixed(0) < 10 ? `0${item.duration.seconds.toFixed(0)}` : item.duration.seconds.toFixed(0) : "-"}</td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>{item.state}</td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>
                      {
                        item.state === "end" ? <img src={src} style={{ width: "20px", height: "20px" }} /> : <span>-</span>
                      }
                    </td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1.2rem", paddingLeft: 16 }}>
                      {
                        item.state === "end" ||
                          item.state === "customer_not_connect" ?
                          "-" :
                          <Dropdown>
                            <Dropdown.Toggle style={{ backgroundColor: "#7A63FF", borderColor: "#7A63FF" }} id="dropdown-basic">
                              {strings.call_actions}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {item.state === "active" && <Dropdown.Item onClick={async () => {

                                const { call } = await this.callActions(item.slug, true, false);


                                const _index = calls.findIndex((c) => c.slug === item.slug);
                                const _calls = calls;
                                _calls[_index] = call;
                                this.setState({
                                  calls: _calls
                                });
                              }}>{strings.resolve}</Dropdown.Item>
                              }
                              <Dropdown.Item onClick={async () => {
                                const _call = await sendRequest("technicians/call", {
                                  slug: item.slug
                                }, "POST");
                                const { path, pin } = _call.data.call;
                                await localStorage.setItem("pin", pin);
                                // onClose();
                                this.setState({ pathname: path });
                              }}>{strings.connect_the_call}</Dropdown.Item>
                              <Dropdown.Item onClick={async () => {
                                const { call } = await this.callActions(item.slug, false, true);
                                const _index = calls.findIndex((c) => c.slug === item.slug);
                                const _calls = calls;
                                _calls[_index] = call;
                                this.setState({
                                  calls: _calls
                                });
                              }}>{strings.customer_is_not_connect}</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                      }
                    </td>
                  </tr>
                )
              })} */}

          </div>
        </div>
      </div>

    )
  }

  renderContent = (strings) => {
    const { calls, selectedState } = this.state;
    if (selectedState === "customer_list") {
      return this.renderCalls(strings)
    } else {
      return this.renderLiveStore(strings);
    }
  }

  renderLiveStore = (strings) => {
    const { features } = techStore;
    if (!features.livestore) return (
      <div className='render-live-store-container' >
        <div className='render-live-store-no-feature'>
          {strings.no_access}
        </div>
      </div>
    )
    return (
      <div className='render-live-store-container'>
        <div className='render-live-store-feature'>

        </div>
      </div>
    )
  }

  callActions = async (id, resolved, customerNotConnect) => {
    return new Promise(async (resolve) => {
      const resp = await sendRequest("technicians/endCall", {
        id,
        note: "",
        resolved,
        sparePart: null,
        customerNotConnect
      }, "POST");
      resolve(resp.data);
    });
  }

  logoutModal = (strings) => {
    confirmAlert({
      title: strings.logout,
      message: strings.logout_header,
      buttons: [
        {
          label: strings.yes,
          onClick: () => {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            techStore.setUser(null, false);
          }
        },
        {
          label: strings.no,
          onClick: () => { console.log("NO") }
        }
      ]
    });
  }



  renderExcel = (strings) => {
    const { user, calls } = techStore;
    const filename = `Selffix - ${user.email} - ${moment().format("DD-MM:YYYY HH:mm")}`
    return (
      <ExcelFile filename={filename} element={<div><img style={{ height: 50, width: 50, padding: "10px" }} src={require("../assets/images/excel.png").default} /></div>}>
        <ExcelSheet data={calls} name="Calls">
          <ExcelColumn label={strings.call_id} value="slug" />
          <ExcelColumn label={strings.status} value="state" />
          <ExcelColumn label={strings.customer} value="customer" />
          <ExcelColumn label={strings.duration} value={
            (col) => `${col.duration ? col.duration.minutes < 10 ? `0${col.duration.minutes}` : col.duration.minutes : "-"}:${col.duration ? col.duration.seconds.toFixed(0) < 10 ? `0${col.duration.seconds.toFixed(0)}` : col.duration.seconds.toFixed(0) : "-"}`
          } />
          <ExcelColumn label={strings.spare_part} value="sparePart" />
          <ExcelColumn label={strings.tech_note} value={(col) => col.note ? col.note : "-"} />
          <ExcelColumn label={strings.resolved} value={(col) => col.resolved ? "YES" : "NO"} />
          <ExcelColumn label={strings.createdAt}
            value={(col) => moment(col.createdAt).format("DD-MM-YYYY HH:mm")} />
          <ExcelColumn label={strings.call_join_date}
            value={(col) => moment(col.callStartedAt).format("DD-MM-YYYY HH:mm")} />
          <ExcelColumn label={strings.call_end_date}
            value={(col) => moment(col.callEndedAt).format("DD-MM-YYYY HH:mm")} />
          {/* <ExcelColumn label={strings.call_end_date}
            value={(col) => moment(col.callEndedAt).format("DD-MM-YYYY HH:mm")} /> */}
        </ExcelSheet>
      </ExcelFile>
    );
  }

  noCallModal = (strings) => {
    confirmAlert({
      title: strings.confirm_submit,
      message: strings.are_you_sure,
      buttons: [
        {
          label: strings.yes,
          onClick: () => alert('Click Yes')
        },
        {
          label: strings.no,
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ onClose }) => <div style={{ backgroundColor: "#FAFAFA", padding: 30, borderRadius: 4, display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>{strings.could_not_start}</span>
        <span style={{ color: "#C9C9C9", fontSize: "1.1rem", marginTop: 10 }}>
          {strings.check_waiting_call}
        </span>
        <div onClick={async () => {
          onClose()
        }} style={{ marginTop: 16, boxShadow: "0px 0px 3px #7A63FF", borderRadius: 4, display: "flex", backgroundColor: "#7A63FF", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ fontWeight: "bold", marginLeft: 10, color: "white" }}>{strings.okay}</span>
        </div>
      </div>,
    });
  }

  callModal = (strings) => {
    confirmAlert({
      title: strings.confirm_submit,
      message: strings.are_you_sure,
      buttons: [
        {
          label: strings.yes,
          onClick: () => alert('Click Yes')
        },
        {
          label: strings.no,
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ onClose }) => <div style={{ backgroundColor: "#FAFAFA", padding: 30, borderRadius: 4, display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>{strings.start_call_with}</span>
        <span style={{ color: "#C9C9C9", fontSize: "1.1rem", marginTop: 10 }}>
          {strings.start_call_desc}
        </span>
        <PhoneInput
          style={{ marginTop: 10 }}
          country={'tr'}
          value={this.state.phone}
          onChange={phone => this.setState({ phone })}
        />
        <div onClick={async () => {
          try {
            const { phone } = this.state;
            const resp = await sendRequest("technicians/createCall", { phone }, "POST");
            const { path, pin } = resp.data.call;
            await localStorage.setItem("pin", pin);
            onClose();
            this.setState({ pathname: path });
          } catch (error) {
            onClose();
            this.noCallModal(strings);
          }
        }} style={{ marginTop: 16, boxShadow: "0px 0px 3px #7A63FF", borderRadius: 4, display: "flex", backgroundColor: "#7A63FF", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ cursor: 'pointer', fontWeight: "bold", marginLeft: 10, color: "white" }}>{strings.start_call}</span>
        </div>
      </div>,
    });
  }

  renderLeft = (strings) => {
    const { selectedState } = this.state;

    return (
      <div className='render-left-container'>
        <div className={selectedState === "customer_list" ? 'render-left-selected-element render-left-elemet' : 'render-left-unselected-element render-left-elemet'} onClick={() => this.setState({ selectedState: "customer_list" })}>
          <img
            src={require("../assets/images/customer_list.png")}
            alt=""
          />
          <span>{strings.customer_list}</span>
        </div>


        <div className='log-out-container' onClick={() => this.logoutModal(strings)}>
          <img
            src={require("../assets/images/logout.png").default}
            alt=""
          />
          <span>{strings.logout}</span>
        </div>
      </div>
    )
  }

  render() {
    const { pathname } = this.state;
    const { strings, calls, call_request } = techStore;
    if (!call_request) return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: 'center', height: "100%", backgroundColor: "#F3F6F9" }}>
        <Loader
          type="Puff"
          color="#7A63FF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>

    );
    if (pathname) {
      return (
        <Redirect
          to={{
            pathname,
          }}
        />
      )
    }

    return (
      <div className='tech-container'>
        <div className="ag-header">
          <div className="ag-header-lead" >
            <img
              className="header-logo"
              src={require("../assets/images/loginIcon.png")}
              alt=""
              style={{ width: "120px", height: "35px", marginLeft: 16 }}
            />
            {this.state.selectedState === "live_store" ? null : <div onClick={() => this.callModal(strings)} style={{ boxShadow: "0px 0px 3px #7A63FF", borderRadius: 4, display: "flex", backgroundColor: "#7A63FF", marginRight: 32, flexDirection: "row", justifyContent: "center", alignItems: "center", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5, cursor: 'pointer' }}>
              <img
                style={{ height: 20, width: 20, marginTop: 2 }}
                src={require("../assets/images/support.png")}
                alt=""
              />
              <span style={{ fontWeight: "bold", marginLeft: 10 }}>{strings.create_call}</span>
            </div>}
          </div>
        </div>
        <div className='tech-inner-container' >
          {this.renderLeft(strings)}
          {this.renderContent(strings)}
        </div>
      </div>
    )
  }
}

export default observer(Tech);

