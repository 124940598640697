import { observable, action, computed, makeObservable } from 'mobx';
import { useRef } from 'react';
import { instance } from '../helpers/axiosWrapper';

class MeetingStore {

  constructor() {
    makeObservable(this, {
      dimensions: observable,
      setDimensions: action,
      elements: observable,
      insertNewElement: action,
      drawer: observable,
      setDrawer: action,
      showDrawer: observable,
      setShowDrawer: action,
      clean: action,
      end: observable,
      setEnd: action,
      rtc: observable,
      setRTC: action,
      showColorPalette: observable,
      setShowColorPalette: action,
      selectedColor: observable,
      setSelectedColor: action,
      isMuted: observable,
      setIsMuted: action,
      intervalId: observable,
      meetingTimer: observable,
      pauseInterval: action,
      startInterval: action,
    });
  }

  dimensions = null;
  drawer = null;
  elements = [];
  showDrawer = false;
  end = false;
  showColorPalette = false;
  isMuted = false;
  rtc = null;
  selectedColor = "#7A63FF";
  intervalId = {};
  meetingTimer = 0;

  setDimensions(dimensions) {
    this.dimensions = dimensions;
  }

  setRTC(rtc) {
    this.rtc = rtc;
  }

  setIsMuted(isMuted) {
    console.log(this.rtc)
    if (this.rtc.localMicTrack) {
      this.rtc.localMicTrack.setMuted(isMuted);
      console.log("🚀 ~ file: meetingStore.js ~ line 51 ~ MeetingStore ~ setIsMuted ~ this.rtc", this.rtc)
      this.isMuted = isMuted;
    }
  }

  setShowColorPalette(showColorPalette) {
    this.showColorPalette = showColorPalette;
  }

  setSelectedColor(selectedColor) {
    this.selectedColor = selectedColor;
    this.setShowColorPalette(false);
  }

  clean() {
    this.showDrawer = false;
    this.drawer.clear();
    this.elements = [];
  }

  setDrawer(drawer) {
    this.drawer = drawer;
  }

  setShowDrawer(showDrawer) {
    this.showDrawer = showDrawer;
  }

  loadNewDrawer(data) {
    this.showDrawer = true;
    setTimeout(() => {
      this.drawer.loadSaveData(data.data, true);
    }, 2000)
  }

  insertNewElement(element) {
    console.log(element);
    this.elements.push(element)
  }

  setEnd() {
    localStorage.removeItem("pin");
    this.end = true;
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.meetingTimer = this.meetingTimer + 1
    }, 1000);
  }

  pauseInterval() {
    clearInterval(this.intervalId)
  }

}


const meetingStore = new MeetingStore();
export default meetingStore;