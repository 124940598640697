export const en = {
  "welcome": "Welcome to SELFFIX",
  "welcome_desc": "Log in with your e-mail and password and you can start the video call by typing the customer's phone number.",
  "sign_in": "Sign in",
  "sign_in_desc": "Sign in with your e-mail and password",
  "email_address": "Email Address",
  "password": "Password",
  "customer_list": "Customer's List",
  "create_call": "Create Call",
  "status": "Status",
  "all": "All",
  "end": "End",
  "active": "Active",
  "customer_not_connect": "Customer not Connect",
  "resolve_status": "Resolve Status",
  "true": "TRUE",
  "false": "FALSE",
  "min_date": "Start Date",
  "max_date": "End Date",
  "search": "Customer Search",
  "clear_filters": "Clear Filters",
  "id": "ID",
  "customer": "Customer",
  "duration": "Duration",
  "waiting": "Waiting",
  "resolved": "Resolved",
  "not_resolved": "Not Resolved",
  "createdAt": "CreatedAt",
  "call_join_date": "Call Join Date",
  "call_end_date": "Call End Date",
  "confirm_submit": "Confirm to submit",
  "are_you_sure": "Are you sure to do this.",
  "actions": "Actions",
  "call_actions": "Call Actions",
  "resolve": "Resolve",
  "connect_the_call": "Connect the call",
  "customer_is_not_connect": "Customer is not connect",
  "logout_header": "Are you sure you want to log out?",
  "logout": "Log Out",
  "start_call_with": "Start call with customer.",
  "start_call_desc": "You can start the video call by typing the customer's phone number.",
  "start_call": "Start Call",
  "waiting_customer": "Waiting Customer...",
  "tech_form": "Tech Form",
  "call_id": "Call Id",
  "pin": "Pin",
  "phone_number": "Phone Number",
  "date_of_call": "Date Of Call",
  "write_notes": "Write here your notes.",
  "is_issue_solved": "Is the issue solved?",
  "yes_solved": "Yes it's solved.",
  "no_solved": "No, Continue with spare part section",
  "answer_not_connect": "Customer can not connect to call...",
  "spare_part": "Spare Part",
  "spare_part_waiting": "Spare part waiting.",
  "end_call": "Are you sure to end this call?",
  "end_spare": "Are you sure need spare part?",
  "customer_not_connect_call": "Customer not connected to call...",
  "yes": "Yes",
  "no": "No",
  "enter_spare": "Enter spare part.",
  "finalize_form": "Finalize the form",
  "code_not_valid": "Code is note valid!",
  "select_shape": "Select a type of shape!",
  "enter_pin": "Start",
  "join_call": "Join Video Call",
  "join_live": "Join live chat with pin code.",
  "live_pin": "Live Chat Pin",
  "ended_chat": "Live Chat Ended",
  "rate_service": "Rate for the service",
  "rate_us": "Rate Us!",
  "please_rate_us": "Please rate our services.",
  "rate_for_service": "Please rate for the service",
  "call_finished": "Call Finished",
  "call_has_finished": "Your call has been finished.",
  "technicians_rate_service": "Technicians please rate for the service",
  "add_note_review": "Add notes for rating.",
  "submit": "Submit",
  "thanks": "Thanks!",
  "thanks_review": "Thanks for your review",
  "leave_call": "Leave Call",
  "check_waiting_call": "Please check if you have a waiting call.",
  "could_not_start": "The call could not be started",
  "okay": "Okay",
  "tech_note": "Technician Note",
  "digit_code": "4-digit code",
  "technician": "Technician",
  "message": "Message",
  "selffix_customer_support": "Selffix Customer Support",
  "end_chat": "End Chat",
  "end_chat_go_rating": "End Chat and Go Rating",
  "live_store": "Live Store",
  "no_access": "NO ACCESS",
  "download": "Download",
  "notes": "Notes",
  "cancel": "Cancel",
  "note": "Note",
  "customer_support": "Customer Support",
  "save": "Save",
  "messages": "Messages",
  "changes_saved": "Changes Saved!",
  "are_u_sure_to_exit": "Are you sure you want to exit?"
}