import axios from 'axios';
import userStore from '../stores/techStore';

export const instance = axios.create({
  baseURL: 'https://api.selffix.co',
  // baseURL: 'http://192.168.1.128:5001'
  // baseURL: "https://api.selffix.co:5011"
});

instance.interceptors.response.use(response => {
  console.log("Request - [response] -> ", response);
  return response;
}, error => {
  console.log(error.response);
  if (error.response.status === 401) {
    //place your reentry code
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    userStore.setUser(null, false);
    alert(error.response.data.message)
  }
  throw error;
});

export function sendRequest(url, data, method) {
  // console.log(data);
  const { user } = userStore;
  console.log("Request - [URL] -> ", url);
  console.log("Request - [data] -> ", data);
  const options = {
    method,
    data: data,
    url,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": user ? user.token : null
    }
  };

  return instance(options);
}

